@import "assets/sass/netop/vars";

.kpis-tree-expand-icon {
  margin-right: 0 !important;
  margin-left: -.9rem !important;
}

.kpis-tree-container {
  height: max(100vh - 520px, 400px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0 1rem 1rem 0;
}

.throughput-down .mat-checkbox-background {
  background-color: $throughput-down !important;
}

.throughput-up .mat-checkbox-background {
  background-color: $throughput-up !important;
}

.traffic-down .mat-checkbox-background {
  background-color: $traffic-down !important;
}

.traffic-up .mat-checkbox-background {
  background-color: $traffic-up !important;
}

.latency .mat-checkbox-background {
  background-color: $latency !important;
}

.client .mat-checkbox-background {
  background-color: $clients !important;
}

.loss .mat-checkbox-background {
  background-color: $loss !important;
}

.assoc .mat-checkbox-background {
  background-color: $assoc !important;
}

.auth .mat-checkbox-background {
  background-color: $auth !important;
}

.dns .mat-checkbox-background {
  background-color: $dns !important;
}

.dhcp .mat-checkbox-background {
  background-color: $dhcp !important;
}

.traffic-predictions .mat-checkbox-background {
  background-color: $traffic-prediction !important;
}

.kpi-tree-info-icon > mat-icon {
  height: 1rem;
  width: 1rem;
  margin-left: .5rem;
  margin-bottom: 1.5rem;
}

.obscure-display {
  opacity: .5;
  pointer-events: none;
}

.kpis-checkbox-list {
  & .checkbox-list-items-container {
    height: max(26vh, 150px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.kpi-tree-search-icon {
  left: 15px;

  & svg path {
    fill-opacity: .65;
    fill: black;
  }
}

.clear-selection-color .mat-raised-button.mat-primary {
  background-color: $soft-gray;
}

.kpis-tree-node-icon.mat-icon {
  width: 0;
}
