@import "assets/sass/netop/vars";

.open-ports-pop-over-container {
  padding: 1rem;
  color: $pale-text;
}

.open-ports-pop-over-menu-wrapper .mat-button {
  overflow: hidden;
  padding: 0;
}
