.health-widget-bar-container {
  flex-grow: 1.15;
  padding-left: 7px;
}

.widget-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
}

.widget-single-chart-close-icon.big-chart-close-icon-container {
  bottom: 3px;
  left: 19px;
  right: 0;
  top: 0;
}

.single-grid-header-title {
  margin-right: 2rem !important;
}
