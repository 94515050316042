.netop-toggle-container {
  display: flex;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid;
  flex-direction: row;
}

.netop-toggle-option {
  cursor: pointer;
  padding: 0rem 1.6rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 15px;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
