@import "assets/sass/netop/vars";
@import "assets/sass/netop/_mixin.scss";

.prop-title-row {
  height: 3.75rem;
  margin-left: 0;
  margin-right: 0;
  width: 100%;

  & .col:first-of-type {
    line-height: 19px;
    font-size: 16px;
    margin: 1rem;
  }

  & mat-icon {
    position: relative;
    left: .9rem;
    color: #467fca;
    cursor: pointer;
    top: .4rem;
  }
}

.prop-expans-panel {
  background-color: $accordion-panel-header-color;
  margin-bottom: 10px !important;
  box-shadow: none !important;
}

.prop-title-list-item .mat-list-item-content {
  padding: 0 !important;
}

.prop-expans-panel-header {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #354052;
}

.prop-expans-panel .mat-expansion-panel-body {
  padding: 0;
}

.properties-mat-accordion-container {
  height: calc(100vh - 420px);
  overflow-y: auto;
  overflow-x: hidden;
}

.device-prop-toolbar {
  max-height: 160px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.prop-toolbar-row {
  height: 35px !important;
  background-color: $accordion-panel-content-color !important;
  padding: 0 5px 0 5px !important;
}

.prop-expans-panel .mat-toolbar-multiple-rows {
  min-height: 0 !important;
}

.prop-content-key {
  font-family: Rubik;
  flex: 1 1 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
}

.show-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.prop-value-field {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #7b8d9b;
  text-transform: capitalize;
  max-width: 8vw;
  @include trim();
}

.prop-content-row {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.prop-key-col {
  max-width: 95%;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden;
}

.policy-prop-key-col {
  @extend .prop-key-col;
  flex-grow: 0.2;
}

.prop-value-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.policy-prop-value-col {
  @extend .prop-value-col;
  justify-content: flex-start;
}

.prop-form-container {
  max-height: 30px;
}

.prop-edit-field {
  position: relative;
  bottom: 10px;
}

.policy-prop-value-field {
  @extend .prop-value-field;
  max-width: 10vw;
}

.policy-row-even {
  background: $list-selection;
}

.device-single-prop-warning {
  margin-bottom: 0.2rem;

  svg {
    height: 18px;
    width: 18px;
  }
}

.device-single-prop-warning-text {
  font-size: 1rem;
}

.properties-content-edit-icon {
  & .col {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
  }

  & .mat-icon {
    color: $soft-gray;
    height: 20px;
    width: 20px;
  }
}

.properties-content-add-icon {
  & .mat-icon {
    color: $soft-gray;
    height: 20px;
    width: 20px;
  }
}
