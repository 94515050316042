@import "../mixin";
@import "../vars";
@import "../col";

.list-view-stack {
  overflow-y: auto;
  max-height: calc(100vh - 175px);

  &.lean {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  &.detailed {
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    color: $pale-text;
    font-weight: 400;

    & .prefix {
      margin-right: 1rem;
    }
  }

  & .items-container {
    display: flex;
    @include wide-screens-only {
      max-width: calc(100vw - 605px);
    }
    @include macbook-pro-and-under {
      max-width: calc(100vw - 471px);
    }

    & .list {
      margin-left: .5rem;
      display: flex;
      max-width: 100%;
      overflow: auto;
      overflow-x: hidden;
      white-space: nowrap;
    }

    & .item {
      display: inline-block;
    }

    & .arrow {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 1.5rem;
      width: 11px;
    }

    & .arrow:first-of-type {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    & .arrow:last-of-type {
      margin-left: 1rem;
      //margin-left: -.25rem;
    }
  }
}

.list-view-filters {
  position: relative;
  bottom: 2rem;
}

.list-view-item {
  cursor: pointer;
  @include hover-selection(#edf6ff);
}

.list-view-item-selected {
  @include active-selection($list-selection);
}

[class*="anomaly-text"] {
  font-size: 1rem;

  &.detailed {
    margin-right: .5rem;
  }
}

.list-view-item-text-out {
  color: $medium-color;
}

.list-view-item-icon {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  &.mat-icon {
    height: 40px;
    width: 40px;
  }

  &-0 svg path:nth-child(1) {
    fill: $devices;
  }

  &-1 svg path:nth-child(1) {
    fill: $packetloss;
  }

  &-2 svg path:nth-child(1) {
    fill: $traffic-prediction;
  }

  &-3 svg path:nth-child(1) {
    fill: $latency;
  }

  &-4 svg path:nth-child(1) {
    fill: $vpnstatus;
  }

  &-5 svg path:nth-child(1) {
    fill: $vpntraffic;
  }

  &-6 svg path:nth-child(1) {
    fill: $userNo;
  }

  &-7 svg path:nth-child(1) {
    fill: #dfdeff;
  }
  &-8 svg path:nth-child(1) {
    fill: #d5eea2;
  }
  &-9 svg path:nth-child(1) {
    fill: #e0bc2b;
  }
}

.list-view-text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @extend .no-side-padding;
}
