@import "assets/sass/netop/dynamic-dashboard.scss";
@import "assets/sass/netop/_mixin.scss";

.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
  font-size: 16px;
  font-weight: inherit;
  color: #192a3e;
}

.kt-portlet .kt-portlet__head {
  color: #6a707e;
}

.kt-portlet {
  margin-bottom: 0;
}

.kt-portlet .kt-portlet__body.portlet-body-entity-list {
  padding: 0;
  margin: 1rem;
  //height: 100%;

  @include wide-screens-only {
    height: max((100vh - 300px) / 2, 320px);
  }
  @include macbook-pro {
    height: max((100vh - 300px) / 2, 305px);
  }

  &.small-right-margin {
    margin-right: 0.1rem;
  }
}

.kt-portlet .kt-portlet__body.portlet-body-entity-list.full-screen-widget-portlet {
  height: max(100vh - 208px, 330px);
}

.kt-portlet .kt-portlet__body.portlet-body-entity-list.full-screen-widget-portlet-no-margin {
  margin: 0;
  height: max(100vh - 148px);
}

.kt-portlet .kt-portlet__body.portlet-body-entity-list.half-widget {
  height: max(100vh - 621px, 200px);
  min-height: 200px;
}

.kt-portlet .kt-portlet__body.portlet-body-entity-list.one-third-widget {
  height: max(100vh - 845px, 100px);
  min-height: 100px;
}

.kt-portlet .kt-portlet__body.portlet-body-entity-list.two-third-widget {
  height: max(100vh - 400px, 200px);
  min-height: 200px;
}

.kt-portlet .kt-portlet__body.portlet-body-entity-list.user-crud-grid {
  height: max(100vh - 260px, 200px);
  min-height: 200px;
}

.kt-portlet .kt-portlet__body.portlet-body-entity-list.no-header-widget-portlet {
  height: max(100vh - 160px, 330px);
}

.kt-portlet .kt-portlet__body.portlet-body-entity-list.with-kpi-bar {
  height: calc(100vh - 227px);
  margin-top: 0;
  margin-bottom: 0;
}

.kt-portlet .kt-portlet__body.portlet-body.device-portlet-body-container {
  height: calc(100vh - 635px);
  min-height: 270px;
}

.kt-portlet .kt-portlet__body.portlet-body-entity-list.portlet-body-entity-list.change-log-list-container {
  height: calc(100vh - 256px);
}

.action-portlet-container {
  margin: 1rem;
}

.graph-portlet {
  border: 2px solid #ebedf2 !important;
}
