.port-forward-tooltip-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.port-forward-list .mat-list-item {
  height: 100% !important;
}
.port-forward-list.mat-list-base {
  padding: 0 !important;
}
.port-forward-list-item .mat-list-item-content {
}
