@import "assets/sass/netop/_mixin.scss";

.info-bar-row {
  width: 100%;
  margin: 0;
  padding-right: 1rem;
  padding-left: 1rem;
  align-items: center;
}

[class*="info-bar-text"] {
  font-family: Rubik;
  color: #5c5c5c;
  font-style: normal;
  font-weight: normal;
  @include trim();
  max-width: 6.5rem;
}

.info-bar-text-main {
  font-size: 1.2rem;
}

.info-bar-text-sub {
  position: relative;
  font-size: 0.9rem;
  margin-top: 0.6rem;
  font-weight: 400;
  overflow: visible;

  i {
    right: 0;
    left: unset;
    transform: translate(50%, -50%);
  }
}

.info-bar-wider-text {
  max-width: 12rem;
}

.info-narrow-col {
  max-width: 16%;
  margin-right: auto;
  margin-left: auto;
}

.margin-from-icon {
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}
