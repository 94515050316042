@import "assets/sass/netop/vars";

.device-dashboard {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.device-dashboard-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.device-dashboard-bar-container, .device-dashboard-statistics {
  border-bottom: 1px inset $purple-greyish-xx-lighter;
}

.device-dashboard-properties {
  width: fit-content;
}

.device-dashboard-bar-container {
  width: 100%;
  height: 7%;
  min-height: 80px;
  max-height: 80px;
}

.device-dashboard-statistics {
  width: 100%;
  height: 50%;
}

.device-dashboard-bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 43%;
}

.device-dashboard-bottom-widget {
  width: 33%;
  height: 100%;
}

.device-dashboard-topology {
  width: 100%;
  height: 100%;
}
