.confirmation-dialog-panel {
  width: auto;
  height: auto;
  overflow-x: hidden;
  overflow-y: hidden;
}

.confirmation-dialog-panel .netop-dialog-content.mat-dialog-content {
  font-size: 15px;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: hidden;
}
