.tenant-settings-container {
  min-height: max(364px, 100vh - 112px);
  overflow-y: auto;
  overflow-x: hidden;
}

.tenant-operation-graph-selector {
  margin-right: 1rem;
  padding: 0.3rem;
}

.tenant-opertaion-graph-type-selectors-row {
  margin-bottom: 1rem;
}
