.info-icon {
  position: relative;
  padding-left: 0;
  right: 6px;
  top: 13px;
}
// .reg-tooltip-container-class .tooltip-inner {
//   background-color: #ffffff;
//   min-width: 300px;
//   min-height: 250px;
// }
// .reg-tooltip-container-class .arrow::before {
//   border-top-color: #ffffff;
// }
.reg-buttons-container {
  max-width: 70%;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}
