@import "assets/sass/netop/vars";

.error-message-text {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #676464;
  font-weight: 600;
}
