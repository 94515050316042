@import "assets/sass/netop/vars";

.row-container {
  background: #ffffff;
  margin-left: 0.1rem;
  //margin-right: 0.1rem;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.venue-fabrics-row-container {
  @extend .row-container;
  height: calc(100vh - 223px);
}

.tplg-col.col {
  padding: 1.5rem;
}

.properties-col {
  min-width: 30rem;
  max-width: 30rem;
  width: 10vw;
  border-left: 2px solid $side-border-color;
}

[class*="prop-btn"] {
  border: 1px solid #3794fc !important;
  box-sizing: border-box !important;
  border-radius: 5px;
  background-color: #2ea1f8 !important;
  width: 17vw;

  &.mat-button {
    min-width: 0;
  }

  &.mat-raised-button {
    min-width: 0 !important;
  }
}

.prop-btn-half-size {
  width: 48%;

  &.with-margin {
    margin-right: 0.9rem;
  }
}
