@import "assets/sass/netop/vars";

.multi-selection .ag-icon-checkbox-checked {
  color: $blue-global;
}

.filter-toggle {
  display: inline-block;
}

.filter-toggle .mat-button-toggle-label-content {
  line-height: 29px !important;
}
.periodic-dialog-content {
  max-height: 70vh !important;
  min-height: 230px;
}
