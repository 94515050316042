.donut-summary-dialog-container .netop-dialog-content {
  padding: 20px !important;
}
.donut-summary-dialog-container .mat-dialog-content {
  max-height: 700px;
  min-height: 600px;
  overflow: hidden;
}

.donut-summary-dialog-container .fabric-list-item {
  height: 3rem;
}
.donut-summary-dialog-container .fabric-list-item:hover {
  height: 3rem;
  align-content: center;
}

.donut-summary-dialog-container .chart-tooltip {
  z-index: 2000;
}

.donut-summary-dialog-container .mat-dialog-container {
  overflow: hidden;
}

.donuts-hidden {
  visibility: hidden;
  opacity: 0;
  height: 0;
  min-height: 0;
  max-height: 0;
  width: 0;
  min-width: 0;
  max-width: 0;
  position: absolute; top: -9999px; left: -9999px;
}

.donut-dialog-nav-tab {
  margin-bottom: 8.5px;
  position: relative;
  top: -11.5px;
}

@media screen and (max-width: 1170px) {
  .donut-summary-dialog-container .vpn-icon-col {
    display: none;
  }
}
