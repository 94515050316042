.time-period-container {
  display: flex;
  align-items: center;
}
.time-period-form-field {
  width: 8vw;
  margin: 1rem;
}

.daily-form-field-flex .mat-form-field-flex {
  width: auto;
}
