.checkbox-with-pl {
  padding-left: 16px;
}
.restore-list-height {
  //max-height: 400px;
  //overflow-y: auto;
}

.checkbox-background-overrider .mat-checkbox-background {
  background-color: red !important;
}
