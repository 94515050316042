@import "src/assets/sass/netop/col";
@import "../shared/list-view";

.anomaly-modal-sub-title-header {
  @extend .row-no-side-margin;
}

[class*="anomaly-modal-sub-title"] {
  font-size: 1.2rem;
}
.anomaly-modal-sub-title-entity {
  display: flex;
  align-items: center;
  max-width: 15%;
  margin-bottom: 1px;
}

.category-icon {
  @extend .list-view-item-icon;
  &.mat-icon {
    height: 25px;
    width: 25px;
  }
}
.anomaly-modal-sub-title-explain {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.1rem;
}

.anomaly-modal-info-icon svg {
  height: 25px;
  width: 35px;
}
