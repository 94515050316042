@import "assets/sass/netop/vars";

//Inspiration: https://codepen.io/run-time/pen/VNRBJd
[class*="hint-text"] {
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding-bottom: 5px;
  padding-top: 5px;
}
.hint-text-container {
  box-sizing: border-box;
  border-radius: 40px;
  position: relative;
  bottom: 55px;
  background: #2ea1f8;
  width: 250px;
}

.hint-text-header-warning {
  color: $negative-color;
  font-weight: 700;
}

.hint-bubble:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid #2ea1f8;
  border-right: 12px solid transparent;
  border-top: 12px solid #2ea1f8;
  border-bottom: 20px solid transparent;
  left: 31px;
  bottom: -26px;

  // content: '';
  // position: absolute;
  // left: 0;
  // top: 50%;
  // width: 0;
  // height: 0;
  // border: 22px solid transparent;
  // border-right-color: #00aabb;
  // border-left: 0;
  // border-top: 0;
  // margin-top: -11px;
  // margin-left: -22px;
}

.hint-icon.mat-icon {
  width: 30px;
  height: 30px;
}
.hint-image {
  width: 55px;
  height: 55px;
}

.no-height {
  height: 0;
}
.isolated-traffic-hint {
  position: relative;
  bottom: 20px;
}
.radius-hint-container {
  position: relative;
  bottom: 130px;
}
.orangeBackground {
  position: relative;
  top: 20px;
}
.access-limits.hint-bubble {
  background: $medium-color;
}
.access-limits.hint-bubble::after {
  border-left: 24px solid $medium-color;
  border-top: 12px solid $medium-color;
}
