.mat-checkbox {
  > label {
    margin-bottom: 0
  }
}

.mat-pseudo-checkbox {
  width: 18px !important;
  height: 18px !important;
}

.mat-pseudo-checkbox-checked::after {
  top: -0.5 !important;
  left: 0 !important;
}
