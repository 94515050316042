@import "src/assets/sass/netop/col";
@import "assets/sass/netop/_mixin.scss";

.main-actions-container-row {
  height: 100%;
  @extend .row-no-side-margin;
}

.main-actions-filters-container {
  height: max(10%, 80px);
  @extend .row-no-side-margin;
}

.main-actions-list-container {
  @extend .row-no-side-margin;
}

.main-action-filters-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    width: auto;
  }
}

.main-action-filter-col {
  width: auto;
}

.main-action-bar-col {
  @include macbook-pro {
    max-width: 9vw;
  }

  @include wide-screens-only {
    max-width: 9vw;
  }
}
