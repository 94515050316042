@import "assets/sass/netop/vars";

.error-label {
  color: red;
}

// ~ Highlight search text
.highlightText {
  color: #ed8ec1;
}

.add-btn {
  cursor: pointer;
  margin: 16px;

  svg {
    width: 40px;
    height: 40px;
  }

  #Oval-5 {
    fill: blue;
  }
}

.kt-label-font-color-2 {
  color: #74788d !important;
}

.fill-remaining-space {
  /* This fills the remaining space, by using flexbox.
       Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.severity-high {
  color: $severity-high;
}

.severity-medium {
  color: $severity-medium;
}

.severity-low {
  color: $severity-minor;
}

.severity-zero {
  color: inherit;
}

/**
Imry: 15.1.20
By product design - when the number of ill is 0, the color should be as the number of totals
On the grid, "severity-zero" did the job with 'color: inherit'.
But on the graph selectors, the inherit is from a different color than the total.
Therefor, I created a new class for zero in the graph (the selector name)
*/
.severity-graph-zero {
  color: #c4c4c4;
}

// Severity background
.bg-severity-high {
  background-color: $severity-high !important;
}

.bg-severity-medium {
  background-color: $severity-medium !important;
}

.bg-severity-low {
  background-color: $severity-minor !important;
}

// Health background
.bg-health-bad {
  background-color: $health-bad-color !important;
}

.bg-health-medium {
  background-color: $health-medium-color !important;
}

.bg-health-good {
  background-color: $health-good-color !important;
}

// Health colors
.health-bad {
  color: $health-bad-color !important;
}

.health-medium {
  color: $health-medium-color !important;
}

.health-good {
  color: $health-good-color !important;
}

/// Priority
.priority-high {
  color: red;
}

.priority-medium {
  color: darkgoldenrod;
}

.priority-low {
  color: green;
}

.fabrics-state-complete {}

.fabrics-state-incomplete {
  color: $severity-medium;
}

.fabrics-state-nointernetconnectivity {
  color: $severity-high;
}

/**
special class for incomplete value at properties content
Need the !important in order to overrirde the original class
*/
.fabrics-prop-state-incomplete {
  color: darkgoldenrod !important;
}

/**
special class for incomplete value at properties content
Need the !important in order to overrirde the original class
*/
.fabrics-prop-state-no-www-connectivity {
  color: $negative-color !important;
}

/**
 * bootstrap button content
*/
.full-width-btn-content {
  width: 98%;
  display: inline-block;
  text-align: left;
  margin: 0;
  font-size: 16px;
  color: #5c5c5c;
}

.netop-scrollbar {
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 16px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #e0e0e0;
  }
}

.netop-scrollbar-thin {
  @extend .netop-scrollbar;

  ::-webkit-scrollbar {
    width: 5px;
  }
}

.netop-scrollbar-hidden {
  ::-webkit-scrollbar {
    display: none;
  }
}
