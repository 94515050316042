@import "assets/sass/netop/vars";

.topology-fabrics .mat-list-base .mat-list-item {
  font-size: 14px;
}

.topology-fabrics .mat-list-item {
  cursor: pointer;
  border: 1px solid $purple-greyish-xxxx-lighter;
  font-family: unset;
}

.topology-fabric.mat-list-item:hover {
  background-color: $purple-greyish-xxxx-lighter;
}

.topology-fabric.topology-fabric-selected.mat-list-item, .topology-fabric.topology-fabric-selected.mat-list-item:hover {
  background-color: $list-selection;
}

.topology-fabrics .mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0 10px;
}

.close-graph-tooltip-icon {
  cursor: pointer;
}

.clients-tooltip-search .search {
  width: 250px;
}
