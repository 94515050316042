.select-list-title {
  padding-left: 16px;
}

.netop-select-list .mat-form-field-appearance-legacy .mat-form-field-wrapper,
.netop-select-list .mat-form-field-wrapper {
  padding-bottom: 0;
}

.netop-select-list .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
  bottom: 0.25em;
}

.netop-select-list .mat-select-trigger, .netop-select-list .mat-select-value {
  display: table-row;
}

.netop-select-list .mat-form-field-infix {
  width: fit-content;
}

.netop-select-list .mat-select-value-text {
  color: #467fca;
}

.netop-select-list .mat-select-arrow {
  margin: 0 10px;
}
