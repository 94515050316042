@import "assets/sass/netop/vars";

.alarms-dashboard-container {
  padding: 14px;
}
.only-grid-total {
  margin-left: auto;
  padding-right: 20px;
  padding-top: 10px;

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
}
.grid-long-strings {
  @include trim();
  display: block;

  //Add !important in order to override ag-cell class, which is also uses !important on the text-overflow property
  // overflow: hidden;
  // text-overflow: ellipsis !important;
  // white-space: nowrap !important;
}

//Added in order to prevent overflow-x appear when modal is opened
.no-dialog-overflow .mat-dialog-container {
  overflow: hidden;
}

.only-grid-total-container {
  position: relative;
  top: 4px;
}
.alerts-cog-icon {
  position: relative;
  top: 2px;
}

//Next class was added for the events, alerts
//Those screens don't use the health dashboard, and therefor do not response to the override of the "kt" classes.
.grid-search-tool-container {
  margin-left: 0;
  flex-grow: 1;
}
.grid-filter-checkbox {
  padding-right: 15px;
}

.cell-content-top {
  justify-content: flex-start !important;
}
