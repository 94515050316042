.force-link {
  cursor: pointer;
}
.force-fake-link {
  stroke-width: 20;
  opacity: 0;
  cursor: pointer;
}

.force-link-text {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 0.8rem;
  cursor: pointer;
}
