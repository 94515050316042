.col-no-flex {
  flex-grow: 0;
}

.col-flex-1 {
  flex-grow: 0.1;
}

.col-flex-2 {
  flex-grow: 0.2;
}

.col-flex-8 {
  flex-grow: 0.8;
}

.col-flex-3 {
  flex-grow: 0.3;
}

.col-flex-4 {
  flex-grow: 0.4;
}

.col-flex-5 {
  flex-grow: 0.5;
}

.col-flex-6 {
  flex-grow: 0.6;
}

.col-flex-7 {
  flex-grow: 0.7;
}

.col-flex-8 {
  flex-grow: 0.8;
}

.col-flex-14 {
  flex-grow: 1.4;
}

.col-flex-13 {
  flex-grow: 1.3;
}

.flex-col-direction {
  display: flex;
  flex-direction: column;
}

.align-center-column {
  display: flex;
  align-self: center;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.align-baseline {
  display: flex;
  align-items: baseline;
}

.justify-center-column {
  display: flex;
  justify-content: center;
}

.justify-end-column {
  display: flex;
  justify-content: flex-end;
}

.justify-space-between {
  display: flex;
  justify-content: space-between;
}

.justify-start-column {
  display: flex;
  justify-content: flex-start;
}

.just-flex {
  display: flex;
}

.col-direction {
  display: flex;
  flex-direction: column;
}

.row-direction {
  display: flex;
  flex-direction: row;
}

.content-in-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width {
  width: 100%;
}

.no-side-padding.col {
  padding-right: 0;
  padding-left: 0;
}

.no-side-margin {
  margin-right: 0;
  margin-left: 0;
}

.row-no-side-margin {
  margin-right: 0;
  margin-left: 0;
  width: 100%;
}

.row-no-side-padding {
  display: flex;
  padding-left: 0;
  padding-right: 0;
}
