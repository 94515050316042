@import "assets/sass/netop/vars";
@import "assets/sass/netop/_mixin.scss";

.tree-parent-header {
  align-items: center;
}

.tree-search {
  width: 80%;
  margin-left: 2%;
}

.tree-search-input {
  padding: 5px;
}

.selection-tree-node {
  display: flex;
}

.selection-tree-child-node {
  align-items: center;
  position: relative;
  right: 12px;
}

.selection-tree-container {
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 5px;
}

.selection-tree-container .mat-tree-node {
  min-height: 48px;
}

.selection-tree-container .mat-checkbox-inner-container {
  width: 13px;
  height: 13px;
}

.selection-checkbox-list {
  & .checkbox-list-items-container {
    height: max(21vh, 150px);
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

.selection-tree-text .span-text {
  text-transform: capitalize;
  margin-left: 5px;
  font-size: 13px;

  @include wide-screens-only {
    max-width: 17.5vw;
  }

  @include macbook-pro {
    max-width: 16vw;
  }

  @include trim();
}

.selection-tree-child-node .tree-leaf-label.span-text {
  margin-top: 2px;
}

.selection-tree-expand-icon {
  margin-left: -1rem !important;
}

img.selection-tree-expand-icon {
  cursor: pointer;
  z-index: 2000;
  width: 9px;
  height: 9px;
}

.obscure-display {
  opacity: .5;
  pointer-events: none;
}

.mat-tree-node .mat-icon-button {
  height: 24px;
  line-height: 24px;
}

.tree-disabled-button {
  background-color: transparent !important;
}

.tree-node-parent-column.mat-tree-node {
  flex-direction: column;
}

.tree-search .mat-form-field-infix {
  width: 200px;
}

.disabled-node {
  opacity: 0.3 !important;
}

.node-loading-container {
  position: relative;
  width: 100%;
  height: 40px;
}

.body-container .mat-checkbox-indeterminate.mat-accent,
.body-container .mat-checkbox-checked.mat-accent {
  &.selection-tree-checkbox-0 .mat-checkbox-background {
    background: #7cb5ec;
  }
  &.selection-tree-checkbox-1 .mat-checkbox-background {
    background: #434348;
  }
  &.selection-tree-checkbox-2 .mat-checkbox-background {
    background: #90ed7d;
  }
  &.selection-tree-checkbox-3 .mat-checkbox-background {
    background: #f7a35c;
  }
  &.selection-tree-checkbox-4 .mat-checkbox-background {
    background: #8085e9;
  }
  &.selection-tree-checkbox-5 .mat-checkbox-background {
    background: #f15c80;
  }
  &.selection-tree-checkbox-6 .mat-checkbox-background {
    background: #e4d354;
  }
  &.selection-tree-checkbox-7 .mat-checkbox-background {
    background: #2b908f;
  }
  &.selection-tree-checkbox-8 .mat-checkbox-background {
    background: #f45b5b;
  }
  &.selection-tree-checkbox-9 .mat-checkbox-background {
    background: #91e8e1;
  }
}
