@import "../globals";

.security-container {
  @media (max-height: 747px) {
    min-height: 364px;
  }
  height: calc(100vh - 94px);
  &.tenants-main-container {
    height: max(364px, 100vh - 109px);
    overflow-y: hidden;
  }
  overflow: hidden;
}

.security-tabs {
  background-color: white;
  .mat-tab-label,
  .mat-tab-link {
    font-size: 18px;
    line-height: 21px;
    opacity: 1;
    color: $blue-global;
  }
}

.security-tab {
  svg {
    fill: $blue-global;
  }
  svg path {
    fill: #467fca;
  }
}

.security-child-components {
  @extend .netop-scrollbar-thin;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 18px;
  height: calc(100vh - 115px);
}

.security-child-components .kt-portlet .kt-portlet__body {
  padding: 8px 25px;
}

.security-rule-dashboard .ag-root-wrapper-body.ag-layout-normal {
  width: 100%;
  height: 100%;
}

.create-security-rule-container {
  .chat-robot-row {
    padding-top: 0;
    padding-bottom: 8px;
  }
}

.create-security-rule-container {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #333;
    opacity: 0.05;
  }
}

.create-security-rule-container {
  .ngx-select_multiple .ngx-select__search {
    width: 100% !important;
  }
}

.security-rule-dashboard-header-text {
  margin-right: 80px;
  font-weight: 500;
  font-size: larger;
  color: $blue-global;
}

.create-security-rule-step {
  flex-direction: column;
}

.rule-builder-port-range-form .mat-form-field {
  display: contents;
}

.rule-builder-port-range-form .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 1.5em 1em;
}

.create-security-rule-container .chat-robot-summary-text {
  min-height: 61vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.create-security-rule-container .summary-container .setting-forms-buttons {
  justify-content: center;
  padding-right: 0;
  margin-top: -10px;
}

.create-security-rule-container .summary-container .chat-robot-summary-btn-cancel {
  padding: 0 10px 0 0;
}

.security-rule-summary-dialog-container {
  .mat-dialog-content {
    overflow: hidden;
  }
}

.security-rule-assignment-dialog-container {
  .mat-dialog-content {
    overflow: hidden;
  }
}

.security-rule-summary-menu .mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0 20px;
}
