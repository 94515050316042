@import "assets/sass/netop/vars";

.integrations-container {
  height: calc(100vh - 176px);
}

.integrations-view {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
  padding-top: 10px;
}

// app-integration-options {
//   height: 30vh;
// }

.integration-options-container {
  width: 100%;
  height: 30vh;
  position: relative;
  overflow-y: auto;

  .mat-grid-list {
    padding: 0 10px 0 10px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 5px;

    .mat-grid-tile {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: rgba(99, 99, 99, 0.1) 0 2px 4px 0;
      width: calc(100% * (1 / 6) - 5px);
      height: calc(50% - 5px);
      border: 2px solid $purple-greyish-xxxx-lighter;
      border-radius: 10px;
      transition: all 0.15s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: rgba(99, 99, 99, 0.01);

        img {
          transform: scale3d(1.1, 1.1, 1.1);
          transition: all 0.15s ease-in-out;
        }
      }
    }
  }
}

.notification-integrations,
.identity-integrations {
  .integration-options-container .mat-grid-tile.disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}

.integration-option-img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  max-width: 300px;
  height: 80%;

  img {
    height: 5vh;
  }
}

.integration-option-name {
  white-space: nowrap;
  text-overflow: clip;
  font-size: 18px;
  font-weight: 600;
}

.integration-dialog-panel {
  min-width: 450px;
  width: 40%;
}

.integrations-grid {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  height: 100%;
}

.integrations-grid .search {
  width: 280px;
}

.integrations-view .mat-divider {
  border-top-width: 2px;
}
