@import "assets/sass/netop/vars";

.small-ellipse.mat-icon {
  height: 54px;
  width: 56px;
}

.small-ellipse svg {
  height: 54px;
  width: 56px;
}

.icon-with-ellipse-small-dot {
  position: relative;
  right: 3.2rem;
  top: 0.2rem;
  & svg {
    height: 10px;
    width: 10px;
  }
}

.icon-with-ellipse-container {
  flex-grow: 0;
  display: flex;
  padding: 0;
  max-width: 3rem;

  &.col {
    padding-left: 0;
    padding-right: 0;
  }
}

[class*="icon-with-ellipse-icon"] {
  position: relative;
  align-self: center;
  display: flex;
}

.icon-with-ellipse-icon-main {
  right: 40px;

  & svg {
    width: 24px;
    height: 24px;
  }
}

.icon-with-ellipse-icon-secondary {
  top: 15px;
  right: 41px;
  & svg {
    width: 15px;
    height: 15px;
  }
}


.icon-with-ellipse-icon svg {
  height: 21px;
  width: 23px;
}

.donut-summary-dialog-container .fabric-list .icon-col {
  transform: scale(0.65);
}

.vpn-connections-list-item .mat-icon {
  transform: scale(0.3);
}

.online-icon svg {
  g, ellipse {
    fill: rgb(144, 205, 150);
  }
}
.offline-icon svg {
  g, ellipse {
    fill: rgb(255, 135, 128);
  }
}
.unknown-icon svg {
  g, ellipse {
    fill: $neutral-color;
  }
}
