@import "assets/sass/netop/vars";
@import "assets/sass/netop/_mixin.scss";
@import "assets/sass/netop/globals";

.dialog-properties {
  &-header-container {
    background: var(--dialog-header-background-color);
    border-radius: 5px 5px 0 0;
    max-height: 54px;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    align-items: baseline;
    position: relative;
  }

  &-container-raw {
    width: 100%;
  }

  &-header {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--dialog-header-foreground-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    padding-left: 37px;
    padding-bottom: 14px;
    padding-top: 17px;
  }

  &-close-icon-container {
    padding-top: 5px;
    flex-grow: 0.1;
  }

  &-close-icon.mat-icon-button {
    color: var(--dialog-header-foreground-color);
  }
}

.confirmation-buttons {
  padding-left: 10px !important;
}

.dialog-integration-content-container {
  min-height: 300px;
}

.sub-header-border {
  border-right: 1px solid #d3d8dd;
  margin-top: 8px;
  flex-grow: 0;
  padding-left: 0;
  height: 70%;
}

.sub-header-text-pad {
  padding-top: 15px;
}

.padding-buttons.netop-dialog-actions.mat-dialog-actions {
  padding-top: 20px;
  padding-right: 20px;
}

.no-overflow-x.mat-dialog-content {
  overflow-x: hidden;
}

.no-overflow-y.mat-dialog-content {
  overflow-y: hidden;
}

.widget-dialog-content.mat-dialog-content {
  max-height: 90vh !important;
}

.analyze-dialog-content.mat-dialog-content {
  max-height: none !important;
  height: 63.5vh;
}

.single-entity-action-dialog-content-container.mat-dialog-content {
  @include wide-screens-only {
    min-height: 65vh;
  }
  @include macbook-pro {
    min-height: 67vh;
  }
  @include retina-display {
    min-height: 37.5vh;
  }
  max-width: none !important;
  max-height: none !important;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-bottom: 10px;
}

.anomaly-content-container.mat-dialog-content {
  min-height: 490px;
}

.anomaly-settings-content-container.mat-dialog-content {
  min-height: 590px;

  & .list-col {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.dev-rep-content-container.mat-dialog-content {
  min-height: 430px;
  max-height: none;
}

.dialog-row-bottom-border {
  border-bottom: 1px solid #ede7e7;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.restore-dialog {
  height: 525px;
}

.backup-now-content {
  height: 122px;
}

.netop-dialog-scrollbar {
  @extend .netop-scrollbar-thin;

  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #e0e0e0;
  }
}
