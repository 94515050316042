.singel-entity-dashboard-row {
  padding-top: 7px;
  padding-bottom: 7px;
  // @media (min-height: 1440px) {
  //   min-height: 1381px;
  // }
  // @media (min-height: 1080px) and (max-height: 1440px) {
  //   min-height: 1021px;
  // }
  /**
    * Althought those are the same size breakpoints
    * leaving them here as a reference of how to do it if the
    * min height should actually change between different resolutions.
    */
  @media (min-height: 748px) {
    min-height: 351px;
  }
  @media (max-height: 747px) {
    min-height: 351px;
  }
  height: calc(50vh - 100px);
}

.single-entity-widget-action-container {
  padding-top: 10px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 7px !important;
}

.single-entity-action-dialog-title-text {
  flex-grow: 3;
}

.single-action-button-container.mat-dialog-actions {
  padding-top: 11px;
  margin-right: 15px;
  margin-left: 2rem;
}

.single-action-button-container-with-action.mat-dialog-actions {
  padding-top: 6px;
}
