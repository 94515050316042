@import "assets/sass/netop/vars";

[class*="diagram-text"] {
  font-size: 12px;
}
.diagram-text-label {
  font-weight: bold;
  left: 8px;
  width: 60%;
  position: relative;
}
.diagram-text-link {
  font-style: oblique;
  font-size: 10px;
  position: relative;
  bottom: 9px;
}

.diagram-container {
  width: 100%;
}
.digram-default-message {
  color: #000000;
  opacity: 0.5;
  font-size: 18px;
  text-align: center;
  padding-left: 20px;
}
.no-width {
  width: 0;
}

[class*="diagram-arrow"] {
}
.diagram-arrow svg {
  height: 40px;
  width: 40px;
}
.diagram-arrow-green svg path {
  fill: $possitive-color;
}
.diagram-arrow-red svg path {
  fill: $negative-color;
}
.diagram-container-row {
  padding-left: 10px;
  display: grid;
  grid-template-columns: 140px 140px 140px 50px;
}
.diagram-container-col {
  display: grid;
  grid-template-columns: 80px 60px;
}
.diagram-arrow-container {
  position: relative;
  top: 5px;
}
.diagram-arrow-icon-with-data {
  position: relative;
  bottom: 20px;
}
.diagram-last-col {
  position: relative;
  right: 10px;
}
