.menu-cell-renderer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: auto;
}

.menu-cell-icon-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  cursor: pointer;
  padding: 0 10px 0 10px;
}
