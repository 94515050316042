.device-type-icon.text-with-icon i {
  width: 20px !important;
  height: 20px !important;
}

.device-status-icon img {
  position: relative;
  bottom: 4px;
  margin-right: 2px;
}
