@import "src/assets/sass/netop/col";
@import "assets/sass/netop/vars";
@import "assets/sass/netop/_mixin.scss";
@import "assets/sass/netop/shared/_error_message.scss";

.reports-dev-sub-title-row {
  @extend .row-no-side-margin;
}

.reports-dev-sub-title-text {
  font-size: 1.2rem;
}

.reports-dev-sub-title-info-col {
  @extend .reports-dev-sub-title-text;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reports-dev-sub-title-entity {
  @extend .reports-dev-sub-title-info-col;
  border-right: 1px solid $side-border-color;
  flex-grow: 0.5;
  min-width: 11vw;
}

.reports-dev-sub-title-first {
  text-transform: capitalize;
  margin-right: 1rem;
}

.reports-dev-sub-title-date {
  display: flex;
  justify-content: flex-end;
  max-width: 15%;
  @include wide-screens-only {
    max-width: 15%;
  }
  @include macbook-pro {
    max-width: 16%;
  }
}

.report-dev-date-picker-field {
  width: max(28%, 134px);

  .mat-form-field-underline {
    bottom: 0;
  }

  .mat-form-field-wrapper {
    padding: 0;
  }
}

.reports-dev-back-to {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  min-width: 150px;
}

.dev-rep-dialog-progress-bar-container {
  width: 95%;
  margin: 1rem;
}

.action-kpi-top-container {
  margin: 1rem;

  &:last-of-type {
    border-top: 1px solid $side-border-color;
  }

  &:first-of-type {
    margin-bottom: 2.95rem;
  }
}

.dev-rep-top-container {
  margin: 1rem;
  &:first-of-type {
    margin-bottom: 2.95rem;
  }
}

.dev-rep-bars-container {
  display: inline-flex;

  &-as-column {
    display: flex;
    flex-direction: column;
  }
}

.dev-rep-bar-header {
  padding: 1rem;
  font-weight: 600;
  font-size: 1rem;

  & .chart {
    padding-bottom: 0;
  }
}

.dev-rep-bars-container-col {
  display: inline-flex;
}

.dev-rep-bars-container-col-direction {
  display: flex;
  flex-direction: column;
  flex-grow: 0.3;
  max-width: 25vw;
}

.venue-deviation-kpi-row {
  position: relative;
  bottom: 2rem;
}

.dev-rep-bars-container-with-back {
  display: flex;
  justify-content: space-between;
}

.dev-rep-progress-list {
  //height: calc(100vh * .185);
  height: calc(100vh - 1100px);
  width: max(100vw - 1450px, 400px);
  min-height: 130px;
  overflow-x: hidden;
  overflow-y: auto;
}

.dev-rep-error-message-text {
  @extend .error-message-text;
  align-items: baseline;
  @include wide-screens-only {
    width: calc(100vw - 1250px);
  }
  @include macbook-pro {
    width: calc(100vw - 1100px);
  }
}

.reports-dev-sub-title-header {
  padding: 0 0 0 10px !important;
}

.five-side-padding.trend-col {
  padding-right: 0px;
}

.title-for-annotation {
  &.graph-title-container {
    min-width: 9rem;
  }

  & span {
    font-size: 0.95rem;
    font-weight: normal;
  }
}
