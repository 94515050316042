@import "assets/sass/netop/vars";

.edit-layout-button {
  &.mat-icon-button {
    margin-right: 1rem;
  }
  &.mat-icon {
    height: 34px;
    width: 34px;
  }
}

.edit-layout-icon path {
  fill: $blue-global;
}
.edit-layout-icon circle {
  stroke: $blue-global;
}
.add-widget-button.mat-raised-button {
  height: 34px;
  width: max(7vw, 9rem);
  min-width: 0 !important;
}

.edit-layout-text {
  color: $blue-global;
  font-size: 1.3rem;
  padding-right: 1rem;
  position: relative;
  top: 0.2rem;
}

.edit-layout-tooltip-content {
  font-size: 1rem;
  font-weight: bold;
}

.edit-layout-tooltip-container .tooltip-inner {
  min-width: 300px;
  max-height: 40px;
}
