@import 'assets/sass/netop/shared/_main-actions-dashboard.scss';
@import 'assets/sass/netop/general-classed';
@import "assets/sass/netop/_mixin.scss";


.tracing-row {
  height: 100%;
  margin-left: 0;
  width: 100%;

  &.tracing-grid {
    height: calc(100vh - 324px);
    margin-right: 0;
  }
}

.tracing-filters-row {
  @extend .main-action-filters-row;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.tracing-tree-col {
  flex-grow: 0.73;
  border-left: 1px solid;
}

.tracing-node-text {
  cursor: pointer;
  text-transform: capitalize;
  @include trim();
  @include macbook-pro {
    max-width: 40rem;
  }
  @include wide-screens-only {
    max-width: 49rem;
  }
}

[class*="tracing-tree-title"] {
  margin: 0.8rem;
  @extend .default-message;
  width: auto;
}

.tracing-tree-title-selected {
  opacity: 1;
  border-bottom: 1px solid;
  text-transform: capitalize;
  font-size: 1.2rem;
  margin-top: 0;
}

.tracing-filter-col {
  @extend .main-action-filter-col;
  display: flex;
  align-items: center;
  border-right: none;
}

.tracing-time-period-col {
  padding-left: 0;
  margin-right: 5.6rem;
  position: relative;
  right: 2rem;
}

.tracing-tree-container {
  max-height: calc(100vh - 400px);
  overflow-y: auto;
}
