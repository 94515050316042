@import "assets/sass/netop/_mixin.scss";
.arrow {
  border-width: 0 3px 3px 0 !important;
  display: inline-table;
  padding: 3px;
  &-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  &-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  &-up {
    @include rotate-arrow("arrow-spin-up");
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  &-down {
    @include rotate-arrow("arrow-spin-down");
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
