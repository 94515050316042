.widget-big-chart-container {
  position: absolute;
  //TODO: Figure out why 100% is bigger than container
  width: 99.2%;
  left: 10px;
  top: 0;
  padding-top: 25px;
  z-index: 1001;
  background: #ffffff;
  border: 1px solid #3794fc;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.widget-big-chart svg {
  width: 100%;
}

.chart-close-icon-container {
  cursor: pointer;
  bottom: 8px;
  right: 8px;
  position: absolute;
}

.big-chart-title {
  padding-left: 28px;
  flex-grow: 0.15;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #192a3e;

  & span:last-of-type {
    font-size: 1rem;
    font-style: italic;
    margin-left: .4rem;
  }
}

.time-span-selection {
  bottom: 33px;
  left: 55px;
  padding: 0;
}

.time-span-selection .mat-select {
  min-width: 70px;
}

.time-span-selection .mat-select-value {
  color: #109cf1;
}

.time-span-selection .mat-form-field-infix {
  width: 78px;
}

.time-span-selection .mat-select-value-text {
  position: absolute;
  top: 3px;
}

.time-span-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #6a707e;
  top: 17px;
}

.dashboard-time-span-selection .mat-form-field-infix {
  width: 78px;
}

.hierarchy-map-time-span .mat-form-field-infix {
  min-width: 10rem;
  width: 100%;
}

.dashboard-time-span-selection .mat-select-value-text {
  position: absolute;
  top: 1px;
}

.dashboard-time-span-selection .dashboard-selection-container {
  position: absolute;
  bottom: 0px;
}

.dashboard-time-span-selection .mat-form-field {
  max-height: 43px;
}

.big-chart-toggle-col {
  position: relative;
  bottom: .4rem;
}
