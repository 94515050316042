.assets-screen.row.dashboard-nav-row {
  background: unset;
}

.assets-screen .tabs-col {
  justify-content: flex-start;
}

.assets-screen .dynamics-nav {
  margin-left: unset;
}

.assets-screen .dashboard-single-tab .mat-tab-links, .assets-screen .dashboard-tabs .mat-tab-links {
  background: unset;
}

.assets-screen .dashboard-tabs mat-ink-bar:before, .assets-screen .dashboard-tabs mat-ink-bar:after {
  background-color: unset;
}

.assets-screen-content {
  width: 100%;
  height: calc(100vh - 251px);
  max-height: 69vh;
}
