@import "assets/sass/netop/vars";



.incident-summary .mat-tab-body-wrapper {
  height: 100%;
}

.incident-summary-tab {
  width: 100%;
  height: 100%;
}

.incident-summary-grid {
}

.incident-summary-text-list.mat-list-base .mat-list-item, .incident-summary-text-list.mat-list-base .mat-list-option {
  border-bottom: 1px inset $purple-greyish-xxxx-lighter;
  font-size: 13px;
}

.incident-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.new-incident-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-weight: 600;
  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
