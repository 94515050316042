$resize-side-size: 14px;
$resize-side-span-weight: 7px;
$resize-side-span-length: 20px;

.resizable {
  position: relative !important;
}

.resize-side {
  position: absolute;
  display: block;
  z-index: 1;
  line-height: $resize-side-size;
}

.resize-left {
  left: 0;
  top: 0;
  width: $resize-side-size;
  height: 100%;
}

.resize-right {
  right: 0;
  top: 0;
  width: $resize-side-size;
  height: 100%;
}

.resize-top {
  left: 0;
  top: 0;
  width: 100%;
  height: $resize-side-size;
}

.resize-bottom {
  left: 0;
  bottom: 0;
  width: 100%;
  height: $resize-side-size;
}

.resize-side span {
  position: absolute;
  display: block;
  width: $resize-side-span-weight;
  height: $resize-side-span-length;
  cursor: col-resize;
  user-select: none;
  box-sizing: border-box;
  border: solid #ccc;
  border-width: 0 1px;
}

.resize-left span {
  top: 50%;
  margin: -10px 0 0 0;
}

.resize-right span {
  top: 50%;
  margin: -10px 0 0 0;
}

.resize-top span {
  left: 50%;
  transform: rotate(90deg);
  margin: 0 0 0 -10px;
}

.resize-bottom span {
  left: 50%;
  transform: rotate(90deg);
  margin: 0 0 0 -10px;
}
