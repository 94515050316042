@import "assets/sass/netop/vars";

.header-menue-container .mat-menu-content {
  padding: 0 !important;
}

.notification-icon svg path {
  fill: var(--top-menu-foreground-color);
}
.header-nav-logo-color svg path {
  fill: var(--top-menu-foreground-color);
}
.header-search-icon-container svg path {
  fill: var(--top-menu-foreground-color);
}
