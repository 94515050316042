.action-icon {
  cursor: pointer;
  color: #459edd;
}

.lan-interface-label {
  width: 81%;
}

.access-limitiation-ip-fields {
  width: 16.5%;
}
.port-forward-remove-button {
  position: relative;
  right: 15px;
}
.port-diagram-container {
  padding-bottom: 20px;
  border-bottom: 1px solid #ede7e7;
  height: 100px;
  max-width: 500px;
}
.port-summary-container {
  /**(100vh-280px) is the total summary container (same as the left column)
  249 = 100 for the diagram height + 70 for the buttons height + 14 for padding bottom
  **/
  height: calc((100vh - 280px) - 183px);
  overflow-y: auto;
  overflow-x: hidden;
}
.fafa-background {
  background: #fafafa;
}
