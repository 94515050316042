@import "assets/sass/netop/_mixin.scss";
@import "assets/sass/netop/vars";

.horizontal-timeline-main-row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.timespan-row {
  align-items: center;
  margin-left: 0;
  margin-right: 0;
}
.timespans-col {
  //padding-right: 0;
  //padding-left: 0;
}
.span-container {
  margin-right: 1px;
  cursor: pointer;
}
.marker {
  position: absolute;
  z-index: 1;
  transform: translate(-50%, -50%);
  padding: 0 !important;
  &:hover {
    opacity: 0.6;
  }
}
.marker-tooltip {
  color: #000000 !important;
  font-size: 1rem;
  transition: opacity 0.7s;
  background: #ffffff !important;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
