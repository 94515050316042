@import "assets/sass/netop/vars";
@import "assets/sass/netop/_mixin.scss";

.accordion-row {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  background-color: $accordion-panel-content-color;
}

.accordion-expans-panel {
  margin: 1rem;
}

.accordion-expans-panel .mat-expansion-panel-body {
  padding-top: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  @include wide-screens-only {
    max-height: 20.6vh;
  }
  @include macbook-pro {
    max-height: 18vh;
  }
}

.accordion-expans-panel .mat-expansion-panel-header {
  background-color: $accordion-panel-header-color !important;

  &:hover {
    background-color: $accordion-panel-header-color;
  }
}

[class*="accordion-text"] {
  text-transform: capitalize;
}

.accordion-text-value {
  max-width: 12vw;
  @include trim();
}

.accordion-row-marker {
  margin-bottom: 0.3rem;
  border-bottom: 1px solid black;
}
