@import "assets/sass/netop/vars";

.arrow-icon {
  position: relative;
  bottom: 8px;
  right: 3px;
  font-size: 20px;
}

.arrow-hover-blue svg:hover {
  fill: $blue-global;
}
