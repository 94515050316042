@import "assets/sass/netop/vars";

.disable-hover:hover {
  background-color: white !important;
}

.last-backup-cell {
  background-color: $negative-color !important;
}

.icon-cell-renderer.mat-icon {
  margin-top: 5px;
  width: 40px;
  &.hide{
      opacity: 0;
  }
}

.icon-cell-renderer.mat-icon svg {
  height: 33px;
  width: 32px;
}

.ellipse-cell-renderer svg {
  height: 15px;
  width: 47px;
}

.health-bar-warning {
  height: 0;
  width: 0;
  position: relative;
  right: 14px;
  margin-bottom: 5rem;

  svg {
    height: 15px;
    width: 15px;
  }
}
