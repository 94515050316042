.graph-donut-portlet {
  border: 2px solid #ebedf2 !important;
}

.center-no-data-text .issues-donut-graph {
  display: none;
}

.center-no-data-text .issues-donut-no-actions {
  width: 280px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
}

.graph-title span {
  font-weight: 600;
}
