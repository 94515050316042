canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

[class*="chart-tooltip"] {
  opacity: 1;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  min-width: 250px;
  display: flex;
  font-size: 1rem;
  font-family: Rubik;
}

.stacked-tooltip {
  width: max(15%, 180px);
}

[class*="with-arrow"]::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  filter: drop-shadow(0px 1px 0px 1px rgba(0, 0, 0, 0.5));
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
}

.with-arrow-left::after {
  border-left: 8px solid #ffffff;
  right: -7px;
}

.with-arrow-right::after {
  border-right: 8px solid #ffffff;
  left: -7px;
}

.with-arrow-above::after {
  border-top: 8px solid #ffffff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  transform: translate(50%, 100%);
  -webkit-transform: translate(50%, 100%);
  right: 50%;
  top: 69%;
}

.with-arrow-under::after {
  border-bottom: 8px solid #ffffff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  transform: translate(50%, 1%);
  -webkit-transform: translate(50%, 1%);
  right: 50%;
  top: -22%;
}

.chartjs-tooltip-key {
  display: inline-block;
  // border: 10px solid transparent;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  // color: #0000;
}

.graph-donut-portlet .chart-tooltip {
  z-index: 2000;
}
