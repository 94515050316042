@import "assets/sass/netop/_col.scss";
@import "assets/sass/netop/_mixin.scss";
@import "assets/sass/netop/tenants/_entity-tree.scss";

.int-manager-container {
  @extend .row-no-side-margin;
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
}

.int-tenant-list-row {
  height: max(100vh - 475px, 150px);
  margin-top: 1rem;
}

לֹ
.int-list-item {
  cursor: pointer;
  @include hover-selection(#edf6ff);
}

.int-list-item-selected {
  @include active-selection($list-selection);
}

.int-list-item-row {
  @extend .row-no-side-margin;
  @extend .row-no-side-padding;
  padding: 1rem;
  height: 30px;
  align-items: center;
  cursor: pointer;

  .col {
    height: 30px;
    display: flex;
    align-items: center;


  }
}

.int-list-text {
  font-size: 1.1rem;
}

.int-list-titles {
  @extend .row-no-side-margin;
  border-bottom: 1px solid black;
  margin-bottom: 1rem;
  margin-top: 2.2rem;
  font-size: 1rem;
}

.org-int-list-container {
  border-right: 1px solid black;
  @include wide-screens-only {
    flex-grow: 0.3;
  }
  @include macbook-pro {
    flex-grow: 0.4;
  }
}

.int-form-buttons-container {
  width: 100%;
  margin-top: 1rem;
}

.int-form-update-button.mat-raised-button {
  min-width: 0 !important;
  width: 164px;
}

.org-int-form-field {
  max-width: 19vw;
}

.int-form-section-container {
  padding: 2rem;
}

.int-form-container form {
  padding: 10px 1px;
}

.int-list-item-text {
  text-transform: capitalize;
  margin-left: 1rem;
}

.int-form {
  max-height: 106px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.int-tenant-list-container {
  height: 100%;
  max-height: 44vh;
  overflow-y: auto;
  overflow-x: hidden;

  mat-list {
    height: 100%;
  }
}

.int-tenant-list-text {
  font-size: 1rem;
  max-width: 95.5%;

  & .mat-nav-list .mat-list-item {
    font-size: 1rem;
  }
}

.tenants-list-header {
  margin-left: 1.2rem;
}

.int-tenant-buttons-row {
  height: 50px;
}

.int-org-list-title {
  display: flex;
  justify-content: space-between;

  .mat-list-icon {
    cursor: pointer;
  }

  svg {
    fill: #4db2ff;
    width: 34px;
    height: 34px;
  }

  .mat-ripple {
    overflow: unset;
  }
}

.int-org-list-header {
  margin-top: 0.3rem;
}

.int-org-list-container {
  height: calc(100vh - 375px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 2rem;
}
