@import "assets/sass/netop/vars";

.action-icon {
  color: $blue-global;

  &.menu-icon {
    margin-left: 2rem;
  }
}

.remove-icon-button {
  position: relative;
  right: 3rem;
  cursor: pointer;
}

.tabs-col {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: calc(100vw - 460px);
}

.edit-layout-col {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.6rem;
  align-items: center;
  min-width: max(11vw, 214px);
}

.dynamics-nav {
  margin-left: 11rem;
}

.dashboard-nav-row .mat-tab-link {
  min-width: 88px;
}
