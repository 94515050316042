
.header-profile-icon.header-profile-icon.mat-mini-fab.mat-accent {
  box-shadow: initial;
  border: 1px solid var(--top-menu-foreground-color);
  color: var(--top-menu-foreground-color);
  width: 37px;
  height: 37px;

  .mat-button-wrapper {
    padding: 0;
  }

  &.for-login {
    height: 4rem;
    width: 4rem;

    .mat-button-wrapper {
      font-size: 2.5rem;
      margin-top: 1rem;
    }
  }
}
