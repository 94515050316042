@import "assets/sass/netop/vars";

.statistics-filter-container .ngx-select_multiple {
  flex-direction: column;
}

.statistics-tree-dialog-panel {
  width: 38%;
  min-width: 400px;
  max-width: 80vw;
  height: 80%;
}

.statistics-tree-dialog-panel .mat-dialog-content {
  height: calc(100vh - 306px);
  overflow: hidden;
}

.statistics-screen .time-period-input-container {
  margin-top: 2px;
}

.visibility-tree-container .selection-tree-container {
  padding-top: 3px;
  padding-bottom: 3px;
}
