.spinner-loading-hide {
  visibility: hidden;
}

mat-progress-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-48%, -50%);
}
