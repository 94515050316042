.properties-container .mat-expansion-panel {
  display: unset;
  box-sizing: unset;
  overflow: unset;
}

.properties-container .mat-expansion-panel-header {
  width: fit-content;
  height: 24px;
  padding: 0;
  font-family: unset;
  font-size: unset;
  font-weight: 600;
}

.properties-container .mat-expansion-indicator::after {
  margin-bottom: 5px;
}

.properties-container .mat-expansion-panel-header.mat-expansion-toggle-indicator-before .mat-expansion-indicator {
  margin: 0 8px 0 0;
}

.properties-container .mat-expansion-panel-header.mat-expanded {
  height: 24px;
}

.properties-container .mat-expansion-panel-body {
  padding: 0;
}

.properties-container .mat-expansion-panel-content {
  font: unset;
}

.properties-sidebar {
  padding: 10px;
}
