@import "assets/sass/netop/_mixin.scss";

.venue-kpi-toggle-container .mat-button-toggle-group {
  height: 2rem;
  align-items: center;
}

.venue-kpi-list-item-text .span-text {
  text-transform: capitalize;
  cursor: text;
  @include wide-screens-only {
    max-width: 17.5vw;
  }
  @include macbook-pro {
    max-width: 22vw;
  }
  @include trim();
}

.mat-list-base .checkbox-container.mat-list-item .mat-list-item-content {
  padding-left: 0;
}
