.arrow-medium path {
  fill: $severity-medium;
}
.arrow-high path {
  fill: $severity-high;
}
.arrow-low path {
  fill: $severity-minor;
}
.arrow-critical path {
  fill: $severity-critical;
}
