@import "assets/sass/netop/vars";

[class*="action-conf-dialog-text"] {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}
.action-conf-dialog-text-button-question {
  color: $neutral-color;
  font-weight: 700;
}
.action-conf-dialog-text-explain {
  padding-left: 10px;
}
.action-conf-dialog-pl-20 {
  padding-left: 20px;
}
.action-conf-dialog-text-key {
  padding-left: 25px;
  font-weight: 600;
}
.action-conf-data-row {
  padding-bottom: 25px;
  padding-right: 15px;
  height: 50%;
}


