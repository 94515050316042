@import "assets/sass/netop/_mixin.scss";
@import "assets/sass/netop/vars";

$hover-icon-color: #dfdfe2;

[class*="org-type-icon"].mat-icon {
  position: relative;
  bottom: 1rem;
}

.org-crud-icon.mat-icon {
  color: $blue-global;
  cursor: pointer;

  &:hover {
    background: $hover-icon-color;
  }
}

.org-crud-icon-disabled.mat-icon {
  color: $disabled-color;
}

.org-type-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
}

.org-type-name {
  max-width: 13vw;
  @include trim;

  &:hover {
    text-decoration: underline;
  }
}

.add-org-type-button.mat-raised-button {
  min-width: 0 !important;
  width: 5rem;
}

.prop-section-dialog {
  height: max(100vh - 591px, 325px) !important;
}

.org-type-dialog-content.mat-dialog-content {
  max-height: none;
  height: 100%;
  overflow-x: hidden;
}

.org-type-list-selected {
  background-color: #eff6ff;
}
