/**
* ngx-select with options devided by puting it into a special container
*/
.separated-options-ngx-container .ngx-select__item.dropdown-item:after {
  content: "";
  /* border-bottom: 1px solid black; */
  width: 100%;
  height: 1px;
  width: 100%;
  background-color: #dadada;
  display: block;
  margin-bottom: -4px;
}
.ngx-select_multiple {
  .ngx-select__search {
    min-height: 42px;
    // height: calc(1.5em + 1.3rem + 2px);
  }
}
