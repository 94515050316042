.assignment-dialog-container {
  overflow: hidden;
}

.assignment-dialog-container .mat-dialog-container {
  overflow: hidden;
}

.assignment-dialog-container .mat-dialog-content {
  min-height: 66vh;
  max-height: 66vh;
  overflow: hidden;
}
