@import "assets/sass/netop/vars";

.venues-management-accordion {
  display: flow-root;
  height: calc(100vh - 240px);
  overflow-x: hidden;
  overflow-y: auto;
}

.venues-management-accordion.mat-accordion .mat-expansion-panel {
  border-bottom: 2px inset $purple-greyish-xxxx-lighter;
}

.venues-management-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.venues-management-accordion mat-expansion-panel-header {
  flex-direction: row-reverse;
  font-size: 16px;
  font-weight: 600;
}

.venues-management-accordion .mat-expansion-indicator {
  margin-right: 8px;
  margin-bottom: 3px;
}

.venues-management-accordion .mat-expanded .mat-expansion-indicator {
  margin-top: 8px
}

.venue-form {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
