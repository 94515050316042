@import "assets/sass/netop/_mixin.scss";

.fabric-rule-text-area {
  position: relative;
  bottom: 0.85rem;

  & .mat-form-field-flex {
    background: white;
    margin-right: 12px;
  }
}

.fabric-rule-row {
  max-width: 100%;

  & .mat-form-field:first-of-type {
    max-width: 76%;
  }

  & .col:first-of-type {
    max-width: 26%;
    padding-right: 0;
  }

  & .urls-col {
    max-width: 26%;
    padding: 0;
  }

  & .toggle-col {
    @include wide-screens-only {
      max-width: 26%;
    }
    @include macbook-pro {
      max-width: 31%;
    }
    @include inch-13-and-under {
      max-width: 35%;
    }
    display: flex;
    position: relative;
    top: 0.5rem;
    padding: 0;
  }

  & .col:last-of-type {
    max-width: 13%;
    display: inline-flex;
    position: relative;
    top: 0.5rem;
  }

  & .mat-input-element {
    overflow: hidden;
  }
}

.toggle-override {
  padding: 0rem .6rem !important;
}

.disable-icon {
  pointer-events: none;
  opacity: .2;
}

