.setting-title {
  font-size: 16px;
  font-weight: inherit;
  color: #192a3e;
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.mat-icon-button.setting-edit-icon {
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  svg {
    fill: #4db2ff;
  }
}
.is-edit-mode.mat-icon-button.setting-edit-icon svg {
  fill: black;
  opacity: 0.5;
}
.setting-forms {
  // padding-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.setting-forms-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  padding-bottom: 14px;
}

.setting-forms-body {
  flex: 0 1 90%;
}
.setting-crud-buttons-container {
}
.entities-curd-container {
  justify-content: center;
  display: flex;
  height: 100%;
}
.org-map {
  height: max(100vh - 658px, 134px);
  width: 27.5vw;
  margin-bottom: 1rem;
}
