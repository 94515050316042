@import "assets/sass/netop/vars";

.under-const-text-container {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.under-const-text {
  font-size: 22px;
  color: $soft-gray;
  text-transform: capitalize;
}
