@import "assets/sass/netop/vars";
@import "assets/sass/netop/mixin";

$widget-body-height: 296px;
$widget-body-wide-screen: 340px;
$widget-body-macbook-pro: 325px;

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.widget-list.cdk-drop-list-dragging {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.widgets-row {
  padding-top: 7px;
  padding-bottom: 7px;
  flex-shrink: 1;
  flex-wrap: wrap;
  //min-height: 44vh;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.widget-row-device-dashboard {
  max-height: 38.75vh;
}

[class*="widget-col"] {
  margin-left: 7px;
  margin-right: 7px;
}

.widget-col:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.widget-col-0 {
  max-width: 22vw;
  min-width: 15vw;
}

.widget-col-1 {
  min-width: 63vw;
}

.widget-col-2 {
  min-width: 85.5vw;
  @include inch-13-and-under {
    min-width: 81.5vw;

  }
}

.widget-size-selector {
  max-height: 30px;
}

.entire-widgets-container {
  height: calc(100vh - 108px);
  overflow-y: auto;
  overflow-x: hidden;
}
