@import "assets/sass/netop/vars";

$button-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
$button-shadow-without-left: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 0px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

.button-with-arrow {
  background: $material-primaty-button;
  box-shadow: $button-shadow-without-left;

  &.mat-button,
  .mat-flat-button,
  .mat-icon-button,
  .mat-stroked-button {
    min-width: 0;
    padding-right: 0;
    padding-left: 0;
    border-radius: 0 4px 4px 0;
  }

  & mat-icon {
    color: #FFFFFF;
  }
}

.button-override-border {

  &.mat-raised-button {
    border-radius: 4px 0 0 4px;
  }
}

.button-in-portlet-header.mat-raised-button {
  height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0 !important;
  width: fit-content;
}

.time-range-button.mat-raised-button {
  width: fit-content;
  min-width: 0 !important
}

.button-off {
  color: white;
  background-color: $accent-color;
}

.button-on {
  color: white;
  background-color: blueviolet;
}
