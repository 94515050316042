@import "assets/sass/netop/vars";

.analyze-pop-over-menu.mat-menu-panel {
  max-width: none;
  overflow-x: hidden;
}

.analyze-pop-over-container {
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  color: $pale-text;
  max-width: 70vw;
}

.analyze-pop-over-box-title {
  padding-bottom: .3rem;
  font-size: 1.3rem;
  text-decoration: underline;
}

.analyze-pop-over-icon-container {
  justify-content: flex-end;
  margin-right: 0;
  margin-left: 0;
  margin-top: 2rem;
  width: 100%;

  & .mat-icon {
    height: 15px;
  }
}

.analyze-pop-over-insight-key {
  width: 4.5rem;
}

.analyze-pop-over-insight-row {
  display: inline-block !important;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  flex-wrap: inherit;
  margin-bottom: 0.2rem;

  & ul {
    padding: 0;
    margin-left: .5rem;
  }
}

.analyze-pop-over-box-row {
  margin-top: 1rem;

  & .col:not(:last-of-type):not(.analyze-pop-over-insight) {
    border-right: 1px solid $side-border-color;
  }

  & .col:not(:first-of-type):not(.analyze-pop-over-insight) {
    padding-left: 2rem;
  }

  & .key {
    font-weight: 600;
  }
}


.analyze-pop-over-inside-dialog {
  & .col {
    height: 12.5rem;
    overflow-y: auto;
  }

  .analyze-pop-over-icon-container {
    margin-top: initial;
  }
}

.analyze-pop-over-insight {
  padding: 0;

  & .key {
    font-weight: 600;
  }
}

.analyze-pop-over-menu .mat-menu-content {
  padding: 0 !important;
}

.analyze-pop-over-menu-wrapper .mat-button {
  padding: 0;
  font-size: 13px;
}
