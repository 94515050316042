@import "assets/sass/netop/vars";
// TODO: make this a percent probably using flex
// .graph-type-selectors {
//   width: 150px;
// }

/// Fix the padding of the avatar button (have to be here due to encapsulation)
.main-navigation-bar {
  .mat-mini-fab .mat-button-wrapper {
    padding: 0;
  }
}
.search-icon {
  position: absolute;
  width: 14px;
  right: 0;
  top: 50%;
  transform: translate(-100%, -50%);
}

.search-container {
  margin-right: 20px;
  .ngx-select__toggle.btn.form-control {
    height: 30px;
    margin-top: -2px;
  }
}

.search-input {
  position: relative;
  margin-right: 8px;
  display: inline-block;
}

.search {
  height: 30px;
  width: 180px;
  background: #ffffff;
  border: 1px solid #c9d1da;
  box-sizing: border-box;
  border-radius: 3px;
  //   padding-right: calc(1.5em + 0.75rem);
  // border: 1px solid rgba(255, 255, 255, 0.5);
  // box-sizing: border-box;
  // border-radius: 3px;
  // color: white;
}
.search-icon svg path {
  fill: rgba(152, 152, 152, 0.61);
}

%tabs {
  .mat-tab-label,
  .mat-tab-link {
    font-size: 15px;
    color: #467fca;
    opacity: 1;
    margin-right: .4rem;
  }
  .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: inherit;
  }
  background: #ffffff;
  flex-direction: row;
}
/**
 * The health/actions/map tabs
*/
.dashboard-tabs .mat-tab-links {
  @extend %tabs;
  justify-content: center;
}
/** Settings/Operations single tab */
.dashboard-single-tab .mat-tab-links {
  @extend %tabs;
  justify-content: flex-start;
}

.mat-drawer .mat-drawer-inner-container {
  .mat-list-base .mat-list-item {
    color: var(--left-menu-foreground-color);
    // color: rgba(0, 0, 0, 0.87);
  }
  background: var(--left-menu-background-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // color: #f2f2f2;
}

// The inner content of the tabs on tenants operations pages
.operations-settings-child-components {
  min-height: calc(100vh - 180px);
  height: 100%;
  .fabrics-list-container {
    height: calc(100vh - 244px);
  }
}

.full-height-container {
  height: 100%;
}

.toggle-container .mat-list-text {
  padding-left: 6px !important;
}
