@import "assets/sass/netop/vars";

.pressed-button .mat-button-toggle-label-content {
  line-height: 33px !important;
  background: $neutral-color !important;
}

.clicked-pressed-button .mat-button-toggle-label-content {
  background: $possitive-color !important;
}
