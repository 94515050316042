@import "src/assets/sass/netop/col";
@import "assets/sass/netop/vars";
@import "assets/sass/netop/_mixin.scss";
@import "assets/sass/netop/dynamic-dashboard.scss";

.graph-type-selectors {
  min-width: 250px;
}

.kt-portlet .kt-portlet__body.portlet-body,
.portlet-body {
  @include wide-screens-only {
    min-height: $widget-body-wide-screen;
  }
  @include macbook-pro {
    min-height: $widget-body-macbook-pro;
  }
  padding: 16px;
}

.kt-portlet .kt-portlet__body.portlet-body.trend-graph,
.portlet-body.trend-graph {
  padding: 10px 0px 0 10px;
}

.portlet-header {
  //   letter-spacing: 0.01em;
  font-size: 16px;

  .time-span-label {
    font-size: 12px;
  }

  // Mat select does not take any width by default without mat-form which takes 100px by default
  mat-select {
    padding-left: 5px;
    font-size: 12px;
    min-width: 70px;
  }

  .mat-select-value {
    color: #109cf1;
  }
}

/** the icons on issues & actions donut graph */
%issues-donut-legend-item-icon {
  content: url("../../../../assets/media/netop/legends/donut-legend-item.svg");
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 16px;
}

.severity-critical-item-icon {
  @extend %issues-donut-legend-item-icon;
  content: url("../../../../assets/media/netop/legends/donut-legend-item-11.svg");
}

.severity-high-item-icon {
  @extend %issues-donut-legend-item-icon;
  content: url("../../../../assets/media/netop/legends/donut-legend-item-1.svg");
}

.severity-medium-item-icon {
  @extend %issues-donut-legend-item-icon;
  content: url("../../../../assets/media/netop/legends/donut-legend-item-2.svg");
}

.severity-low-item-icon {
  @extend %issues-donut-legend-item-icon;
  content: url("../../../../assets/media/netop/legends/donut-legend-item-3.svg");
}

/**
* The same as the category itself, the icons/css class names starts from the number just after the
* three severity icons.
*/
.category-item-icon-operational {
  @extend %issues-donut-legend-item-icon;
  content: url("../../../../assets/media/netop/legends/donut-legend-item-4.svg");
}

.category-item-icon-compliance {
  @extend %issues-donut-legend-item-icon;
  content: url("../../../../assets/media/netop/legends/donut-legend-item-5.svg");
}

.category-item-icon-performance {
  @extend %issues-donut-legend-item-icon;
  content: url("../../../../assets/media/netop/legends/donut-legend-item-6.svg");
}

.category-item-icon-security {
  @extend %issues-donut-legend-item-icon;
  content: url("../../../../assets/media/netop/legends/donut-legend-item-7.svg");
}

.category-item-icon-8 {
  @extend %issues-donut-legend-item-icon;
  content: url("../../../../assets/media/netop/legends/donut-legend-item-8.svg");
}

.category-item-icon-9 {
  @extend %issues-donut-legend-item-icon;
  content: url("../../../../assets/media/netop/legends/donut-legend-item-9.svg");
}

.category-item-icon-10 {
  @extend %issues-donut-legend-item-icon;
  content: url("../../../../assets/media/netop/legends/donut-legend-item-10.svg");
}

// The entity list component container of ag grid styles
.entity-list-component-host {
  height: 100%;
}

// The entity list ag grid styles
.grid-widget {
  //height: max(100vh - 585px, 298px);
  height: max(100vh - 725px, 236px);
  width: 100%;
  box-sizing: border-box;
}

// The entity list ag grid styles
.small-grid-widget {
  //height: calc(100vh - 575px);
  height: 300px;
  width: 100%;
  box-sizing: border-box;
}

.health-grid-total-text {
  color: $pale-text;
  margin-left: 0.8rem;
  font-style: italic;
}

%dot {
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  right: 0;
}

/**
 * style of a bullet in actions grid category column
 */
.issues-category-operational {
  @extend %dot;
  background-color: #1f78b4;
}

.issues-category-compliance {
  @extend %dot;
  background-color: #8a7dd0;
}

.issues-category-performance {
  @extend %dot;
  background-color: #c2bce6;
}

.issues-category-security {
  @extend %dot;
  background-color: #a6cee3;
}

.issues-category-5 {
  @extend %dot;
  background-color: #58b8f1;
}

.issues-category-6 {
  @extend %dot;
  background-color: #5fcccc;
}

.issues-category-7 {
  @extend %dot;
  background-color: #81afff;
}

// Change the padding to make the space between portlet 14px
.dashboard-portal {
  // Move the toolbar aside of the tool bar with margin to the left to separate
  // the search from the title text
  .dashboard-search-tool-container.kt-portlet__head-toolbar {
    margin-left: 50px;
    flex-grow: 1;
  }

  .col-xl,
  .col-xl-auto,
  .col-xl-12,
  .col-xl-11,
  .col-xl-10,
  .col-xl-9,
  .col-xl-8,
  .col-xl-7,
  .col-xl-6,
  .col-xl-5,
  .col-xl-4,
  .col-xl-3,
  .col-xl-2,
  .col-xl-1,
  .col-lg,
  .col-lg-auto,
  .col-lg-12,
  .col-lg-11,
  .col-lg-10,
  .col-lg-9,
  .col-lg-8,
  .col-lg-7,
  .col-lg-6,
  .col-lg-5,
  .col-lg-4,
  .col-lg-3,
  .col-lg-2,
  .col-lg-1,
  .col-md,
  .col-md-auto,
  .col-md-12,
  .col-md-11,
  .col-md-10,
  .col-md-9,
  .col-md-8,
  .col-md-7,
  .col-md-6,
  .col-md-5,
  .col-md-4,
  .col-md-3,
  .col-md-2,
  .col-md-1,
  .col-sm,
  .col-sm-auto,
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1,
  .col,
  .col-auto,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2,
  .col-1 {
    padding-right: 7px;
    padding-left: 7px;
  }
}

//This class was added alerts screen.
//It needed for separation the search box and the setting icon
.grid-toolbar-row {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

// The kt-portlet, dasboard-content, dashboard-row & bootstrap col will make overall 20px padding
/**
 * The dashboard container
 */
.dashboard-content {
  // padding: 7px;
  // overflow-y: hidden;
}

// Make the cells content of the dashboard grids to be well vertically centered
.ag-grid-dashboard-row .ag-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ag-dashboard-grid-row-with-hover:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.ag-dashboard-grid-cell-hover-row {
  .ag-cell:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
}

.ag-grid-override-pointer {
  &.ag-dashboard-grid-cell-hover-row {
    .ag-cell:hover {
      cursor: default;
    }

    &.ag-dashboard-grid-row-with-hover:hover {
      cursor: default;
    }
  }
}

// Dashboard cell
.ag-dashboard-grid-cell {
  line-height: 38px;
}

/**
 * Dashboard grid height
*/

.dashboard-row {
  padding-top: 7px;
  padding-bottom: 7px;
  // @media (min-height: 1440px) {
  //   min-height: 1381px;
  // }
  // @media (min-height: 1080px) and (max-height: 1440px) {
  //   min-height: 1021px;
  // }
  /**
  * Althought those are the same size breakpoints
  * leaving them here as a reference of how to do it if the
  * min height should actually change between different resolutions.
  */
  @media (min-height: 748px) {
    min-height: 370px;
  }
  @media (max-height: 747px) {
    min-height: 370px;
  }
  // min-height: 344px;
  /** header  40px, Health/Actions tab 48px, margins before and after (the calc includes the row paddings already)*/
  /* 6.1.2020 changed the padding to 14px (7+7) instead of 20px but apperently this size still works.
  Thought had to do some "manual" corrections. Evgeny */
  height: calc(50vh - 55px);
}

.dashboard-tabs {
  // Making the ink bar (the bar below tabs text) smaller by adding before & after elements
  mat-ink-bar {
    background-color: $blue-global !important;

    &:before,
    &:after {
      content: "";
      height: 2px;
      width: 20%;
      background-color: white;
      position: absolute;
      left: 0;
    }

    &:after {
      left: initial;
      right: 0;
    }
  }
}

.show-application-label {
  font-size: 12px;
}

.dashboard-nav-row {
  background: #ffffff;
  border-bottom: 1px solid #d8dce6;
  @extend .row-no-side-margin;
}

.dashboard-nav-row .mat-tab-nav-bar {
  border-bottom: none;
}

.dashboars-nav-col {
  position: relative;
  left: 120px;
}
