@import "assets/sass/netop/_mixin.scss";
@import "assets/sass/netop/vars";

.tplg-node {
  cursor: pointer;
}

.tplg-svg-container {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}

.tplg-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  text-anchor: middle;
}

.tplg-link {
  cursor: pointer;
}

.tplg-link-text {
  font-family: sans-serif;
  color: black;
  padding: 5px;
  visibility: hidden;
}

.tplg-title {
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.tplg-device-zoom-container {
  bottom: 15px;
}

.tplg-venue-fabrics-zoom-container {
  bottom: 100px;
}

.zoom-button {
  color: #4c5862;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-left: 9.25px;
  margin-top: 15px;
}

[class*="tplg-zoom"] {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #979797;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  width: 31px;
  height: 40px;
}

.tplg-zoom-in-container {
  border-radius: 30px 30px 0 0;
}

.tplg-zoom-out-container {
  border-radius: 0 0 30px 30px;
}

.tplg-clicked-link {
  stroke-width: 2px;
}

.tplg-not-clicked-link {
  stroke-width: 1px;
}

.zoom-container {
  //Adding the white background is important in order to prevent case where the svg is being dragged
  //on top of the zoom buttons
  //With the white backgroung, even if the svg element is dragged to the zoom buttons territory, it won't cover it
  background: #ffffff;
}

.disconnected-devices-container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 0.35;
  //Adding the white background is important in order to prevent case where the svg is being dragged
  //on top of the disconnected device
  //With the white backgroung, even if the svg element is dragged to the disconnected devices territory, it won't cover it
  background: #ffffff;
  z-index: 10;
  right: 5px;
}

.disconnected-venue-fabrics-devices-container {
  flex-grow: 0.3;
}

.tplg-title-col {
  flex-grow: 0.5;
  background: transparent;
}

.topology-header-row {
  height: 50px;

  & .entity-name-selector {
    height: 1.2rem;
    position: relative;
    bottom: 2px;

    & :hover {
      height: 1.3rem;
    }
  }
}

.topology-header-row-for-device {
  height: 30px;
}

.tplg-checkbox-container {
  padding-top: 10px;
}

.tplg-topology-row {
  height: calc(100vh - 420px);
  margin-top: 1.2rem;
}

.tplg-device-topology-row {
  position: relative;
  bottom: 0;
  max-height: 180px;
  min-height: 120px;
}

.tplg-venue-fabric-topology-row {
  height: calc(100vh - 460px);
  margin-top: 4.6rem;
}

.tplg-error-message-container {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tplg-error-message-text {
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.tplg-device-zoom-parent {
  position: relative;
  bottom: 40px;
}

.fade-in-out-message {
  @include fade-in-out(var(--global-red));
  padding-left: 10px;
  opacity: 0;
}

.footer-for-widget {
  //top: 20px;
}

.footer-regular {
  top: 30px;
}

[class*="tplg-footer"] {
  position: relative;
  display: flex;
}

.tplg-footer-left-container {
  align-self: flex-end;
  background: #ffffff;
}

.tplg-footer-right-container {
}

.tplg-footer-device-right-corner {
  display: flex;
  top: -15px;
  left: 5px;
}

.tplg-sync-icon {
  padding-left: 3px;
  position: relative;
  top: 9px;
  cursor: pointer;
}

.sync-icon {
  color: #467fca;
}

.sync-icon.mat-icon.material-icons {
  font-size: 21px;
  height: 21px;
  width: 21px;
}

.spin-icon {
  @include rotate-icon();
}

.tplg-center-header-container {
  display: flex;
  justify-content: center;
  @include wide-screens-only {
    flex-grow: .9;
  }
  @include macbook-pro {
    flex-grow: .6;
  }
}

.tplg-header-view-icon {
  & .mat-icon-button {
    position: relative;
    bottom: 1.5rem;
    left: 1.6rem;
  }

  & .mat-icon {
    color: $icons-blue-global;
  }
}
