.reports-sender-row {
  margin: 1rem 0;
  width: 100%;
}

[class*="reports-sender-col"] {
  display: flex;
  align-items: center;

  & > span {
    min-width: 12rem;
  }

  & .reports-sender-label {
    min-width: 6rem;
    position: relative;
    top: 0.3rem;
    margin-right: 1rem;
  }

  & .thin {
    max-width: 8rem;
  }
}

.reports-sender-button.mat-raised-button {
  min-width: 0rem !important;
  width: auto;
}
