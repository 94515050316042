@import "assets/sass/netop/vars";

.dashboard-header-left .mat-form-field-infix {
  width: auto;
}

.dashboard-widget-toolbar .mat-form-field-appearance-legacy .mat-form-field-infix {
  display: flex;
  padding: 0 0 2px;
}

.dashboard-widget-toolbar .mat-form-field-infix {
  border-top: 0;
}

.dashboard-widget-toolbar-top mat-form-field,
.dashboard-widget-toolbar-top .mat-form-field-infix,
.dashboard-widget-toolbar-top input {
  width: 100% !important;
}

.select-template.netop-select-list .mat-select-value-text {
  font-size: 16px;
}

.dashboard-template-settings-panel .mat-dialog-content {
  height: 320px;
}

.edit-dashboard-fab.mat-fab {
  width: 32px;
  height: 32px;
  line-height: 28px;
  margin-left: 20px;
}

.edit-dashboard-fab.mat-fab .mat-button-wrapper {
  padding: 2px 0;
}

.dashboard-widget-name {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}

.dashboard-widget-name.mat-input-element {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}

.dashboard-widget-toolbar-icon {
  cursor: pointer;
  color: $blue-global;
}

.dashboard-widget-settings-icon {
  margin-bottom: 10px;
}
