.single-portlet-container {
  margin: 7px;
}
.single-portlet-body {
  height: calc(100vh - 175px);
}
// .half-size-widget {
//   height: max(100vh - 548px, 210px);
//   //height: calc(100vh - 548px);
// }
.vpn-widgets-container {
  height: calc(100vh - 113px);
  overflow-x: hidden;
  overflow-y: auto;
}
