@import "assets/sass/netop/_mixin.scss";

[class*="tplg-tooltip-text"] {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
}

.tplg-tooltip {
  position: fixed;
  z-index: 3000;
  &-container {
    width: 265px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    background: white;
    color: rgba(0, 0, 0, 0.87);
  }
  &-content-container {
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &-title {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
  &-icon {
    padding-left: 28.6px;
    flex-grow: 0.06;
  }
  &-text-percentage {
    top: 3px;
    position: relative;
    flex-grow: 0.01;
    padding-left: 0;
    top: 5px;
    position: relative;
  }

  &-text-reason {
    color: #5c5c5c;
    top: 5px;
    position: relative;
    padding-right: 20px;
  }

  &-content {
    padding-bottom: 10px;
  }

  .row-client-container {
    padding-left: 20px;
    justify-content: space-between;
    padding-bottom: 5px;
  }

  .single-client-key {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    padding-right: 20px;
  }
  .multi-client-sub-title {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #000000;
  }
  .single-client-value {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #7b8d9b;
  }
}

.single-device-tooltip {
}
.single-client-tooltip {
  width: 300px;
}
.multi-clients-rows-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}
.multi-clients-col-icon-container {
  padding-right: 10px;
  justify-content: center;
  display: flex;
}
.multi-clients-row-icon-container {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}
.tplg-dot {
  position: relative;
  bottom: 3px;
  right: 9px;
}
.tplg-dot-uknown {
  position: relative;
  bottom: 11px;
  left: 1px;
}
.multi-clients-number {
  margin-left: 0.4rem;
  bottom: 10px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  font-size: 12px;
}
.multi-client-rssi-header {
  padding-left: 40px;
  display: flex;
}
.multi-client-table {
  width: 100%;
  height: 115px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  bottom: 25px;
}
.multi-client-table-row {
  width: 100%;
}
.multi-client-table-col {
  display: flex;
  justify-content: center;
  &:nth-of-type(1) {
    margin-left: 1.3rem;
  }
}
[class*="multi-client-cell"] {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
}
.multi-client-cell-key {
  @include trim();
  width: 76px;
}

div.multi-client-table-col {
  &:first-child {
    border-right: 1px solid #d3d8dd;
  }
}
div.multi-client-table-col {
  &:last-child {
    border-left: 1px solid #d3d8dd;
  }
}
.multi-client-legend-row {
  width: 100%;
  position: relative;
  bottom: 24px;
}
.multi-client-legend-col {
  display: flex;
  justify-content: center;
}
.multi-client-legend-text {
  color: #ffffff;
  &-infinity {
    font-size: 25px;
    position: relative;
    bottom: 11px;
    left: 5px;
    color: #ffffff;
  }
}

.tplg-failed-client-col {
  border-left: 1px solid #d3d8dd;
  overflow-y: auto;
  overflow-x: hidden;
}

.tplg-tooltip-content-failed {
  padding-left: 15px;
  padding-bottom: 5px;
  width: 300px;
  @include trim();
}
.tplg-tooltip-content-failed-container {
  max-height: 15rem;
  overflow-y: auto;
}
.tplg-tooltip-content-title {
  padding-left: 10px;
  padding-bottom: 10px;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}
