.force-node-circle {
  cursor: pointer;
  transition: stroke-width 0.1s ease-out, fill 0.1s ease-out, stroke 0.1s ease-out;
  stroke-width: 2;
  filter: url("#shadowFilter");
  cx: 0;
  cy: 0;
}

.clicked-force-node-circle {
  filter: url("#clickedShadowFilter");
  stroke: #75b3fb;
}
[class*="force-node-text"] {
  cursor: pointer;
  text-anchor: middle;
  alignment-baseline: central;
  font-weight: 400;
  text-transform: capitalize;
}
.force-node-text-name {
  font-weight: 500;
}
.force-node-text-type {
  font-style: italic;
}
