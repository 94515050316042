.as-link {
  color: #0000ee;
  cursor: pointer;
  &:visited {
    color: #551a8b;
  }
  &:hover {
    text-decoration: underline;
  }
}
