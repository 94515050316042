@import "assets/sass/netop/_mixin.scss";
@import "assets/sass/netop/vars";

.full-size {
  height: 100%;
  width: 100%;
}

.no-overflow {
  overflow: hidden;
}

.no-ellipse {
  text-overflow: none;
}

.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}

.no-pointer-events {
  pointer-events: none;
}

.inherit-pointer-events {
  pointer-events: inherit;
}

.obscurity {
  pointer-events: none;
  opacity: 0.1;

  &-canvas {
    @extend .obscurity;
    opacity: 1;
  }
}

.obscurity-half {
  pointer-events: none;
  opacity: 0.6;
}

.medium-obscurity {
  @extend .obscurity;
  opacity: 0.5;
}

.to-camel-case {
  text-transform: capitalize;
}

.default-message {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #000000;
  opacity: 0.7;
  font-size: 1.6rem;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  & span {
    font-size: 1.2rem;
    color: $pale-text;
    font-weight: bold;
  }
}
