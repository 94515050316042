.dynamic-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.dynamic-form .mat-select {
  padding-top: 10px;
}

.password-field {
  display: flex;
  flex-direction: row;
}

.password-field .mat-form-field {
  width: 100%;
}
