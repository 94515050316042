.tooltip-container-class .arrow::before {
  border-top-color: #ffffff;
}

.information-icon-container {
  padding-right: 1rem;
}

.information-icon-container svg {
  height: 15px;
  width: 15px;
}

[class*="information-tooltip-text"] {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 14px;
}

.information-tooltip-text-header {
  text-align: center;
  font-weight: 600;
}

.tooltip-row {
  padding: 10px;
}

.tooltip-col {
  display: flex;
  justify-content: flex-start;
}

.registration-screen-tooltip-container .tooltip-inner {
  min-width: 22vw;
}

