.reports-sender-dialog-panel {
  width: auto;
  min-width: 400px;
  max-width: 86vw !important;
  height: auto;
  min-height: 200px;
  max-height: 800px;
  overflow: hidden !important;
}

.reports-sender-dialog-panel .mat-dialog-content {
  width: auto;
  height: auto;
  overflow: hidden !important;
  padding: 20px !important;
}

.reports-sender {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  height: 100%;
}

.reports-sender-selections {
  display: flex;
  row-gap: 15px;
}
