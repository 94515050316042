@import "assets/sass/netop/vars";
@import "src/assets/sass/netop/col";
@import "assets/sass/netop/_mixin.scss";

.single-container {
  padding-top: 7px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  padding-bottom: 0 !important;
}

.single-tpg-column-container {
  padding-top: 7px;
  // @media (min-height: 748px) {
  //   min-height: 680px;
  // }
  // @media (max-height: 747px) {
  //   min-height: 680px;
  // }
  //Calculation: 88px (UI header + tabs) + 57 (widgets height) + 52 (14px padding between elements) = 197:
  // After calculation, I saw I could lower the main columns in 4px more without scrolling so I did.
  height: calc(100vh - 194px);
  justify-content: flex-end;
}

.single-tpg-column {
  border: 1px solid #e6eaee;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  width: 100%;
  padding-bottom: 0 !important;
  padding-top: 10px;
  height: 100%;
}

.single-prop-body-container {
  border: 1px solid #e6eaee;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  padding-top: 0 !important;
  height: 100%;
}

.single-column-box {
  margin-bottom: -15px;
  flex-grow: 1.8;
  padding-left: 7px;
  padding-right: 7px;
}

.single-widget-col {
  padding-left: 7px;
  padding-right: 7px;
}

.single-widget-big-chart-container {
  position: absolute;
  width: 98%;
  height: 70%;
  margin: 0rem;
  background: #ffffff;
  border: 1px solid #3794fc;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 2rem;
  z-index: 11;
}

.single-big-chart {
  @include wide-screens-only {
    padding-bottom: 45px;
  }
  @include macbook-pro {
    padding-bottom: 67px;
  }
  padding-left: 60px !important;
}

.single-prop-column-container {
  height: calc(100% - 5px) !important;
}

.single-entities-kpis-row {
  margin: 0.5rem 0.8rem 1.8rem 0.5rem;
}

.single-entities-main-row {
  margin-left: 7px;
  margin-right: 10px;
}

.single-tplg-title.mat-list-base {
  padding-top: 0.3rem;
}

.single-venue-fabrics-title {
  margin-bottom: 1rem;
}

.single-kpi-widget:nth-of-type(1) {
  padding-left: 0;
}

.single-kpi-widget:last-of-type {
  padding-right: 0;
}

.single-kpi-widgets-row {
  padding-bottom: 7px;
}

.policy-arrow {
  font-size: 27px;
}

.policy-arrow-allow {
  color: $possitive-color;
}

.policy-arrow-deny {
  color: $negative-color;
}

.policy-ignore {
  color: $negative-color;
}

.policy-underline {
  border-bottom: 1px solid black;
}

.policy-value-tooltip-container .tooltip-inner {
  min-width: 275px;
  max-width: 301px;
}

.policy-arrow-tooltip-container .tooltip-inner {
  min-width: 100px;
  max-width: 301px;
}

.policy-tooltip-rows-container {
  width: 100%;
  height: auto;
  display: inline-block;
  justify-content: center;
  align-content: center;
}

.policy-tooltip-value {
  max-width: 60%;
}

[class*="fabric-prop-toolbar"] {
  max-height: calc(100vh - 677px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $accordion-panel-content-color !important;
}

.fabric-prop-toolbar-min-height {
  height: 170px;
}

.policy-title {
  border-bottom: 1px solid black;
}

.venue-fabric-prop-icon svg {
  height: 15px;
  width: 15px;
  margin-bottom: 1rem;

  & path {
    fill: black;
  }
}

.global-policy-icon svg {
  height: 15px;
  width: 15px;

  & path {
    fill: black;
  }
}

.fabric-prop-value {
  text-decoration: underline;
  cursor: pointer;
}

.policy-toolbar-row:first-of-type {
  margin-top: 1.2rem;
}

.policy-toolbar-row:last-of-type {
  margin-bottom: 1.2em;
}

.venue-fabric-complete {
  color: $severity-minor;

  circle {
    fill: $severity-minor;
  }
}

.venue-fabric-incomplete {
  color: $severity-medium;

  circle {
    fill: $severity-medium;
  }
}

.venue-fabric-nowwwconnectivity {
  color: $negative-color;

  circle {
    fill: $negative-color;
  }
}

.single-venue-fabric-legend-container {
  @include wide-screens-only {
    width: 60%;
  }
  @include macbook-pro {
    width: 80%;
  }
}

.single-entities-fabric-list-container {
  padding-top: 0;
  border-right: 2px solid $side-border-color;
  @include wide-screens-only {
    flex-grow: 0.4;
  }
  @include macbook-pro {
    flex-grow: 0.5;
  }
}

.fabric-status-display-container {
  box-shadow: 0px 0px 3px 0px $pale-text;
  padding: 0.7rem;
  min-width: 266px;
  color: $pale-text;
}

.fabric-status-title-container {
  justify-content: space-between;
  @extend .row-no-side-margin;

  & .mat-icon {
    height: 10px;
    width: 15px;
    position: relative;
    bottom: 0.9rem;
    cursor: pointer;
  }
}

.fabric-status-icons-container {
  justify-content: flex-end;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0.6rem;
  width: 97%;

  & .mat-icon {
    height: 15px;
  }
}

.fabric-status-single-icon-container {
  margin-right: 0;
  margin-left: 0;
  padding-left: 7px;
  text-transform: capitalize;
  opacity: 0.5;
  //cursor: pointer;
  &:not(:last-child) {
    border-right: 1px solid;
    padding-right: 7px;
  }
}

.fabric-status-row {
  margin-bottom: 0.2rem;
}

.fabric-status-hide-data {
  display: none;
}

