.form-section-title {
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0;
  margin: 0 0 1.75rem 0;

  &.form-section-title-sm {
    font-size: 1.1rem;
  }

  &.form-section-title-md {
    font-size: 1.4rem;
  }

  &.form-section-title-lg {
    font-size: 1.5rem;
  }
}

.wrapper-same-as-picker-height .mat-form-field-wrapper {
  height: 5rem;
}

.mat-error, .inline-error {
  background: white;
  z-index: 10;
  left: 0;
  top: 100%;
  display: inline-block;
  margin-top: 0.1rem;
}

.form-label {
  display: flex;
  align-items: center;
  margin-right: 1em;
  margin-bottom: 8px;
}
