.clone-search-input {
  margin: 1rem;
  max-width: 50%;
}
.clone-search-icon {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 11px;
  bottom: 8px;
}

.clone-search-icon svg path {
  fill: rgba(152, 152, 152, 0.61);
}

.date-inputs {
  margin-left: 1.3rem;
  display: flex;
}

.selection-list {
  height: 400px;
  overflow-y: auto;
}
.clone-dialog-content.mat-dialog-content {
  max-height: 79vh !important;
  overflow-y: auto;
}
.clone-radio {
  //   border-top: 1px solid #ede7e7;
}
