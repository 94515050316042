@import "assets/sass/netop/globals";

.search-menu-box-search .mat-form-field-infix {
  display: contents;
}

.search-menu-box-search .mat-form-field-flex {
  padding-bottom: 6px;
}

.search-menu-box-search .mat-form-field-label {
  font-size: 16px;
  left: 16px;
}

.search-menu-box-search .mat-icon svg path {
  fill: gray;
}

.search-menu-box-search .mat-form-field-label span {
  opacity: 0.4;
  font-style: italic;
}

.search-menu-box {
  @extend .netop-scrollbar-thin;
}

.search-menu-box .mat-list-base {
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 0;
}
