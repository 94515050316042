.bandwidth-settings .search {
  width: 280px;
}

.bandwidth-limit-tree.selection-tree-container {
  height: calc(100vh - 176px);
  overflow-y: auto;
}

.bandwidth-limit-tree.selection-tree-container .mat-tree-node .tree-parent-header {
  cursor: pointer;
}

.bandwidth-limit-tree.selection-tree-text .span-text {
  font-size: 15px;
}

.bandwidth-limit {
  display: flex;
  column-gap: 15px;
  margin-top: 12px;
  margin-left: 20px;
}

.bandwidth-limit mat-form-field {
  display: flex;
  flex-direction: column;
  height: 35px;
}

.bandwidth-limit .mat-form-field-appearance-legacy .mat-form-field-infix {
  width: 110px;
  padding: 0.4em 0 0.075em 0;
  border-top: 0;
}

.bandwidth-limit .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.78125em;
  font-size: 11px;
}

.bandwidth-limit .mat-input-element {
  font-weight: 600;
}

.bandwidth-limit .mat-raised-button {
  width: fit-content;
  height: 26px;
  line-height: 26px;
}
