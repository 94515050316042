.from-to-icon-text {
  padding-top: 6px;
  margin-bottom: 0;
  @include wide-screens-only {
    max-width: 18rem;
  }
  @include macbook-pro {
    max-width: 15rem;
  }
  @include trim();
  .mat-icon {
    transform: scale(.7);
    font-size: 22px;
    width: 26px;
  }
  //svg path {
  //  fill: #e0e0e8;
  //}
}
