.chat-robot-container {
  background: #edf4fc;
  border: 1px solid #dae6f4;
  box-sizing: border-box;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  //   width: 44%;
  min-height: 48px;
  font-size: 18px;
  color: #5c5c5c;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  p {
    margin: 0;
  }
}

.chat-robot-summary {
  background: #fafafa;
  border: 1px solid #dadada;
  box-sizing: border-box;
  // height: 100%;
  // display: flex;
  // flex-direction: column;
  &-text {
    flex: 0 1 90%;
  }
  // &-buttons {
  //   display: flex;
  //   justify-content: center;
  // }
  &-btn-cancel {
    padding: 0 40px 0 0;
  }
  &-btn-copy {
    padding: 20px 0 0 0;
  }
  &-row {
    margin-top: 20px;
  }
}
.chat-container {
  // bootstrap row will create the padding already
  padding: 0px 0px 0px 0px;
}

.chat-robot-row {
  padding-top: 10px;
  padding-bottom: 10px;
}

.chat-robot-summary-no-background {
  background: #ffffff;
}
