// include the init file with function definition e.g. we will need the kt-get function.
@import "../global/init";
@import "../_config";

// Fix theme bug where important padding style in material integration
// overwrites kt-portlet style and breaks portlet footer padding
// 29/9/2019 Evgeny
.kt-portlet .kt-portlet__foot {
  padding: kt-get($kt-portlet, space, desktop) !important;
}
