@import "assets/sass/netop/vars";
@import "assets/sass/netop/_mixin.scss";

app-reports-main {
  >* {
    width: 100% !important;
    margin: 0 !important;
    margin-right: 13px !important;
  }
}

//@include fix-material-inputs;
mat-form-field {
  max-height: 36px !important;

  .mat-mdc-form-field  {
    flex-direction: row;
    align-items: center;

  }
}

.reports-nav-item {
  margin-top: 0.3rem;

  &.mat-tab-link {
    width: 87%;
    display: flex;
    justify-content: flex-start;
  }
}

.reports-filters-bar-container {
  background: #ffffff;
  margin: 0 14px;
}

.reports-filters-bar-row {
  display: flex;
  align-items: center;
  height: 80px;
  max-width: 100%;
  margin: 0;
  padding-top: 0;

  h6 {
    margin: 0;
    margin-top: 1rem;
  }

  mat-form-field {
    padding: 0;
  }
}

.reports-filters-bar-col {
  padding-right: 0;
  padding-left: 0;
  align-items: center;
  height: 100%;
  display: flex;

  >* {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  @include wide-screens-only {
    flex-grow: .7
  }

  @include macbook-pro {
    flex-grow: .6
  }

  @include inch-13-and-under {
    flex-grow: .5
  }
}

.reports-filters-action-col {
  flex-grow: 0.15;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;

  button {
    flex-shrink: 0 !important;
  }
}

.reports-filters-bar-title {
  padding: 1rem;
}

.reports-filters-bar-sub-title {
  margin: 1rem;
  width: 8%;

  &:last-of-type {
    width: 15%;
    margin-right: 0;
  }
}

.period-time-selection-container {
  max-width: 83%;
  padding-top: 1rem;
}

.reports-dashboard-container {
  height: calc(100vh - 208px);
}

.reports-header-row {
  background: white;
  width: 100%;
}

.reports-next-to-text {
  margin-left: 0.4rem;
  color: black;
  font-style: italic;
  font-size: 1rem;
}

.reports-templates-button {
  background: #e7eaf2;
  margin-right: 10px;

  &.mat-button,
  .mat-flat-button,
  .mat-icon-button,
  .mat-stroked-button {
    margin-right: 1rem;
    height: 34px;
    margin-top: 0.3rem;
  }
}

.reports-templates-button-label {
  display: flex;
  align-items: center;
  margin: 1rem;
  font-size: 14px;
}

.arrow-reports {
  border: solid #8f9196;
  margin-left: 0.7rem;
}

.reports-template-option {
  display: flex;

  &:last-child {
    border-top: 1px solid $side-border-color;
  }
}

.reports-edit-icon-container {
  display: flex;
  justify-content: flex-end;
  height: 1rem;
}

.reports-edit-icon {
  margin-right: 1rem;
  color: $blue-global;

  &.mat-icon {
    width: 14px;
  }
}

.reports-crud-icons-container {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1rem;
}

.reports-crud-icons {
  margin-right: 0;
  color: $blue-global;
}

.reports-button-icon.mat-icon-button {
  margin-right: 0;
  height: 24px;
  line-height: 0;
  width: 24px;

  &:first-child {
    margin-right: 0.3rem;
  }
}

.reports-editable-text-container {
  @include trim();
  margin: 1rem;
  min-width: 180px;
  max-width: 181px;
}

[class*="report-template-option"] {
  font-family: Rubik, Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: black;
  cursor: pointer;
}

.report-template-option-input {
  border-bottom: 1px solid black;
}
