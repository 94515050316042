.d-wording-bold-text {
  font-weight: 600;
}

[class*="d-wording-text"] {
  &.mat-button {
    text-transform: lowercase;
  }

  &.mat-menu-item {
    text-transform: lowercase;
  }
}

.d-wording-text-underline.mat-button {
  text-decoration: underline;
  padding-right: 0;
  padding-left: 0;
  min-width: 16px;
}

.d-wording-mat-menu.mat-menu-panel {
  max-height: calc(100vh - 548px);
}

.d-wording-text-input {
  display: inline-block;

  & mat-form-field {
    max-width: 6.3rem;
    text-align: center;
  }

  & .mat-input-element {
    max-width: 3rem;
    text-align: center;
  }
}

.d-wording-form {
  display: inline-flex;
}

.d-wording-error {
  position: absolute;
  top: 80%;
}
