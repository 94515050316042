.destination-is-internal .mat-form-field-infix {
  width: 90px;
  padding: 0.01em 0 0.5em 0;
}

.destination-is-internal .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: unset;
  border: 1px solid lightgrey;
  padding: 0.01em .75em 0 0.75em;
}

.destination-is-internal .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(0%);
}
