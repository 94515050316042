.setting-col-container {
    // set height & overflow to allow inner nodes structure to scroll auth.
    height: 100%;
    min-height: calc(100vh - 280px);
    overflow: hidden;
    &-tree-container {
      background-color: #fafafa;
    }
  }
  
  .org-manage-tenant-no-selection {
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    color: #000000;
    opacity: 0.5;
    font-size: 18px;
  }
  