@import "assets/sass/netop/_mixin.scss";
@import "assets/sass/netop/_dynamic-dashboard.scss";
@import "assets/sass/netop/vars";

$border-color: #dcd7d7;

.ins-anom-sub-title-row {
  background: #ffffff;
  max-height: 10rem;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
}

[class*="ins-anom-widget-container"] {

  .lean {
    margin-top: 1rem;
  }

  .detailed {

  }
}

[class*="ins-anom-col-widget-container"] {
  height: 0;
}

.ins-anom-widget-container-vertical {
}

.ins-anom-vertical-bar-col {
  @include wide-screens-only {
    flex-grow: .5;
  }
  @include macbook-pro {
    flex-grow: .45;
    max-width: 450px;
  }
}

.ins-anom-anomalies-col {
  padding-top: 7px;
  position: relative;
  bottom: 3rem;
}

.ins-anom-widget-container-anomalies {
  &.lean {
    height: max(100vh - 800px, 300px);
  }

  &.detailed {
    //height: $widget-body-height;
  }
}

.ins-anom-widget-container-row {
  width: 100%;
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  justify-content: space-between;

  & .col {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.ins-anom-widget-container-traffic {
  background: #ffffff;
  padding: 1rem;
  height: max(100vh - 550px, 390px);
}

.ins-anom-traffic-close-icon.big-chart-close-icon-container {
  top: -8px;
}

.ins-anom-traffic-row-header {
  padding-bottom: 0 !important;
  height: 15px;
  margin-left: 1rem;
}

.ins-anom-time-col {
  max-height: 100%;

  &:last-of-type {
    border-left: 1px solid $border-color;
    flex-grow: 0.4;
  }
}

.ins-anom-selectors-container {
  height: 100%;
}

.ins-anom-obscurity-icon {
  color: #7f8fa4;
  cursor: pointer;
}

.ins-anom-anomalies-display-date {
  color: #3794fc;
  font-size: 1.1rem;
  border-radius: 15px;
  border: 1px solid;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  width: 12rem;
  line-height: 1.8rem;
}

.ins-anom-widgets-space-filler {
  height: 12px;
  width: 100%;
  background-color: white;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: -1rem;
  opacity: 0.9;
}

.ins-anom-bar-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

.ins-anom-open-action-num {
  margin-left: .8rem;
  color: $negative-color;
  font-style: italic;
}

.ins-anom-time-period-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include wide-screens-only {
    flex-grow: 0.475;
  }
  @include macbook-pro {
    flex-grow: 0.425;
  }
  @include Ipad3-screen {
    flex-grow: 0.7;
  }
}

.ins-anom-text-prefix {
  flex-grow: .2;
  display: flex;
  align-items: center;
  padding-right: 0;
  max-width: 5rem;
  font-size: 1.2rem;
  color: $pale-text;

  @include wide-screens-only {
    flex-grow: .2;
  }
  @include macbook-pro {
    flex-grow: .3;
  }

  &.col {
    padding-right: 0;
  }
}
