@import "assets/sass/netop/vars";

.kpi-bar-col:first-of-type {
  padding-left: 0;
}
.kpi-bar-col:last-of-type {
  padding-right: 0;
}
.kpi-bar-big-chart-container {
  height: 100%;
  width: 100%;
  margin-left: 0;
  margin-top: 1rem;
  padding-top: 25px;
  background: #ffffff;
  border: 1px solid $blue-global;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.kpi-bar-big-chart-col {
  margin-left: 1.2rem;
}
