// https://ng-bootstrap.github.io/#/components/tooltip/api

[class*="tooltip-container-class"] .tooltip-inner {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  max-width: none;
}
.tooltip-container-class .arrow::before {
  border-top-color: #ffffff;
}
.tooltip-container-class-reg .tooltip-inner {
  width: 300px;
}
.tooltip-container-class-port-dorward .tooltip-inner {
  min-width: 235px;
}
