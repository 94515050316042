@import "assets/sass/netop/vars";

.highcharts-credits {
  display: none !important;
}

.highcharts-tooltip text tspan:first-child {
  font-size: 14px !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: $default-text-color;
}

.chart-row-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.chart-col-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.shared-highcharts-legend {
  z-index: 2000000;
}

.plot-band-opacity {
  opacity: 0.2;
}

.custom-highcharts-tooltip span {
  font-weight: 600;
}
