.no-overflow-x .mat-dialog-content {
  overflow-x: hidden;
}
.no-overflow-y .mat-dialog-content {
  overflow-y: hidden;
}

.no-overlow-y {
  overflow-y: hidden;
}
