@import "assets/sass/netop/vars";

.report-schedule {
  display: flex;
  justify-content: flex-start;
}

.reports-scheduler-dialog-panel {
  width: auto;
  min-width: 700px;
  max-width: 86vw !important;
  height: auto;
  min-height: 300px;
  max-height: 800px;
  overflow: hidden !important;
}

.reports-scheduler-dialog-panel .mat-dialog-content {
  overflow: hidden !important;
}

.reports-scheduler {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  height: 100%;
}

.report-scheduler-heading {
  font-size: 13px;
  font-weight: bold;
}

.schedule-report {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.reports-scheduler-button {
  width: fit-content;
  height: fit-content;
}

.report-schedules-container {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid $purple-greyish-xxx-lighter;


  .report-schedules {
    display: flex !important;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    height: 100%;
    padding: 20px 0;

    >mat-list-item {
      display: flex;
      width: 100%;
      padding: 0;

      span {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .report-schedule-col {
          flex-grow: 1;
        }
      }
    }
  }

  .mat-list-base .report-schedule.mat-list-item .mat-list-item-content {
    padding: 0;
    border-bottom: 2px solid $purple-greyish-xxx-lighter;
  }
}
