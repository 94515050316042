.default-page-container {
  background: #26489f;
  background: -moz-linear-gradient(left, #26489f 0%, #17164f 100%);
  background: -webkit-linear-gradient(left, #26489f 0%, #17164f 100%);
  background: linear-gradient(to right, #26489f 0%, #17164f 100%);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .content-container {
    align-items: center;
    display: flex;
    width: 70%;
    height: 100%;
  }

  & .netop-logo-img {
    margin: 2.8rem 3rem 3rem 0;
    max-width: 214px;
  }

  & h1 {
    font-size: 3em;
    font-weight: 600;
    color: #ffffff;
    width: 73%;
  }

  & h2 {
    color: #ffffff;
    margin-top: 3rem;
  }

  & .col {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & .robot-img-col {
    height: 500px;
  }

  & .netop-logo-col {
    justify-content: flex-start
  }
}

.animdiv {
  position: relative;
  height: 100%;
  min-width: 100%;

  & .mainimg {
    display: block;
    border: 0;
    -ms-interpolation-mode: bicubic;
    max-width: 100%;
    max-height: 100%;
  }

  & .img1 {
    right: 0;
    bottom: 100px;
  }

  & .img2 {
    right: -10px;
    top: -10px;
  }

  & .img3 {
    left: 0;
    top: 100px;
  }

  & .animimg {
    position: absolute;
    max-width: 180px;
  }
}
