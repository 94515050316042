@import "assets/sass/netop/vars";

.trend-col {
  flex: 1 1 50%;
  padding-right: 25px;
  padding-left: 35px;
  padding-top: 15px;
}

.graph-title {
  font-weight: 600;
}

[class*="multi-kpi-title"] {
  color: $blue-global;
  font-size: 0.95rem;
  font-style: italic;
}

.multi-kpi-title-key {
  margin-left: 1rem;
  //margin-right: 0.1rem;
  font-weight: bold;
}

.multi-kpi-title-value {

}

.graph-title-container {
  //flex-grow: 0.15;
  //min-width: 7.6rem;
}

.graph-with-border {
  border-top: 1px solid $side-border-color;
  padding-top: 15px;
}

.stacked-container {
  position: relative;
  bottom: 5px;
}
